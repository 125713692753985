import { AnimatePresence, motion } from "framer-motion";
import { type } from "os";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AccountsAccountsLists } from "../../styleComponents/accountsStyle";
import { ExchangeIcon, ExchangeList, ExchangeListIcon, ExchangeListRight, exchangeLogo, ExchangeTitle } from "../../styleComponents/exchangeStyle";
import Account from "./Account";

const ExchangeToggle = (props: any) => {
	const exchangeId = props.exchangeId;
	const reFetchAccount = props.reFetchAccount;
	const data = props.data;
	const [toggle, setToggle] = useState(false);
	const adding = useParams();

	useEffect(() => {}, []);

	return (
		<>
			<ExchangeList whileTap={{ scale: 0.95 }} onClick={() => (data ? setToggle((cur) => !cur) : console.log())}>
				<ExchangeListIcon>
					<ExchangeIcon
						initial={adding.exchangeId ? {} : { scale: 0, opacity: 0, rotateZ: 60 }}
						animate={adding.exchangeId ? {} : { scale: 1, opacity: 1, rotateZ: 0 }}
						transition={{ type: "spring" }}
						src={exchangeLogo[exchangeId]}
					/>
					<ExchangeTitle>{exchangeId}</ExchangeTitle>
				</ExchangeListIcon>
				{data ? (
					<ExchangeListRight
						initial={adding.exchangeId ? {} : toggle ? { rotate: 0 } : { rotate: -90 }}
						animate={adding.exchangeId ? {} : toggle ? { rotate: -90 } : { rotate: 0 }}>
						<i style={{ fontSize: "25px", color: "white" }} className="fa-solid fa-caret-left"></i>
					</ExchangeListRight>
				) : (
					<ExchangeListRight
						key="accountAdded"
						initial={adding.exchangeId ? {} : { opacity: 0, scale: 0 }}
						animate={adding.exchangeId ? {} : { opacity: 1, scale: 1 }}>
						<Link to={`${exchangeId}/add`}>
							<span style={{ fontSize: "20px", color: "white" }}>&rarr; Click To Add</span>
						</Link>
					</ExchangeListRight>
				)}
			</ExchangeList>
			<AnimatePresence mode="wait">
				{data ? (
					toggle ? (
						<AccountsAccountsLists
							initial={{ opacity: 0, height: 0, scale: 0 }}
							animate={{ opacity: 1, height: "auto", scale: 1, transition: { type: "keyframes" } }}
							exit={{ opacity: 0, height: 0, scale: 0 }}>
							{data.map((account: any) => (
								<Account key={`${account.exchange}/${account.name}`} account={account} reFetchAccount={reFetchAccount} />
							))}
							<motion.li
								whileTap={{ scale: 0.95 }}
								key="new"
								style={{
									padding: "5px 20px",
									fontWeight: 500,
									fontSize: "30px",
									borderTop: "1px solid rgba(255,255,255,0.1)",
									boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)",
									backgroundColor: "rgba(255,255,255,0.05)",
									borderRadius: "20px",
									marginBottom: "25px",
								}}>
								<Link to={`${exchangeId}/add`}>+ Add Account</Link>
							</motion.li>
						</AccountsAccountsLists>
					) : null
				) : null}
			</AnimatePresence>
		</>
	);
};
export default ExchangeToggle;
