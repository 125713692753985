import { createBrowserRouter } from "react-router-dom";
import Home from "./Screens/Home";
import Root from "./Root";
import Login from "./Screens/Login";
import ChartScreen from "./Screens/ChartScreen";
import Exchanges from "./Screens/Exchanges";
import Coins from "./Screens/Coins";
import AddAccount from "./Components/AccountComponent/AddAccount";
import Profile from "./Screens/Profile";
import InstallInstruction from "./Components/InstallInstruction";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Root />,
		children: [
			{
				path: "",
				element: <Home />,
			},
			{
				path: "exchanges",
				element: <Exchanges />,
				children: [
					{
						path: ":exchangeId/add",
						element: <AddAccount />,
					},
				],
			},
			{
				path: "profile",
				element: <Profile />,
			},

			{
				path: ":exchangeId/:accountId",
				element: <Coins />,
			},

			{
				path: ":exchangeId/:accountId/:coinId",
				element: <ChartScreen />,
			},
		],
	},
	{
		path: "login",
		element: <Login />,
	},
]);

export default router;
