import { useMotionValue } from "framer-motion";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { accountForTradeState, IExAccount } from "../../atoms/userAccounts";
import { UserList, Leftpart, RightPart } from "../../styleComponents/accountStyle";
import TextLoading from "../TextLoading";

const SwhowAccount = (props: any) => {
	const account: IExAccount = props.account;
	const [isLoading, setIsLoading] = props.loadingState;
	const [userBalance, setUserBalance] = props.balanceState;
	const [wantDelete, setWantDelete] = props.wantDeleteState;
	const setAccountForTrade = useSetRecoilState(accountForTradeState);
	const exchange = props.exchange;

	const x = useMotionValue(0);
	useEffect(() => {
		x.on("change", () => {
			if (x.get() < -200) {
				setWantDelete(true);
			}
		});
	}, [x]);

	return (
		<UserList
			key={`${account.exchange}/${account.name}`}
			style={{ x }}
			drag="x"
			dragSnapToOrigin
			whileTap={{ scale: 0.95 }}
			initial={{ x: -50, opacity: 0, scale: 0 }}
			animate={{ x: 0, opacity: 1, scale: 1 }}
			exit={{ x: -50, opacity: 0, scale: 0 }}>
			<Link key={`${account.exchange}/${account.name}`} to={`/${account.exchange}/${account.name}`} onClick={() => setAccountForTrade(account)}>
				<Leftpart>{account.name}</Leftpart>
				<RightPart>
					{isLoading ? (
						<span>Loading...</span>
					) : userBalance ? (
						<>
							<div>USDT: {userBalance.total.USDT.toFixed(2)}</div>
							{/* <div>Total BTC: {userBalance.total.BTC}</div> */}
						</>
					) : (
						<>
							<div>
								USDT: <TextLoading />
							</div>
							{/* <div>
								Total BTC: <TextLoading />
							</div> */}
						</>
					)}
				</RightPart>
			</Link>
		</UserList>
	);
};
export default SwhowAccount;
