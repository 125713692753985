import { motion } from "framer-motion";
import styled from "styled-components";

export const AccountsWrapper = styled(motion.div)`
	padding: 20% 10%;
	height: 100svh;
	width: 100svw;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
`;
export const AccountsContainer = styled(motion.div)`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 30px;
	box-shadow: 9px 16px 16px hsl(0deg 0% 0% / 0.25);
`;
export const AccountsAccountsLists = styled(motion.ul)`
	width: 85%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 40px;
	border-radius: 20px;
	overflow: hidden;
	border: 1px solid rgba(255, 255, 255, 0.2);
	line-height: 1;
	color: white;
	li {
		padding: 0 10px;
		margin: 5px 0px;
	}
`;

export const AccountsNoAccountLi = styled(motion.li)`
	display: flex;
	justify-content: space-between;
`;
export const AccountsAddLink = styled(motion.span)`
	display: flex;
	align-items: center;
	font-size: 1.5625rem;
`;
export const AccountsAddPart = styled(motion.div)`
	display: flex;
	justify-content: center;
	align-items: center;
	a {
		margin-top: 0.625rem;
		font-size: 1.875rem;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 6.25rem;
		height: 3.125rem;
		padding: 2%;
		border: 2px solid white;
		border-radius: 1.875rem;
	}
`;
