import { motion } from "framer-motion";
import { ChartScreenBottomContainer } from "../../styleComponents/chartScreenStyle";
import TextLoading from "../TextLoading";

const BottomTradeInfo = (props: any) => {
	const { balance, candleUpdate, contents, changeRisk, setChangeRisk, componentRef, risk, setRisk, pnl } = props.data;
	return (
		<ChartScreenBottomContainer initial={{ opacity: 0.5, scale: 0, y: 10 }} animate={{ opacity: 1, scale: 1, y: 0 }} exit={{ opacity: 0.5, scale: 0, y: 10 }}>
			<motion.div
				style={{
					fontSize: "20px",
					display: "flex",
					flexDirection: "column",
					background: "rgba(255,255,255,0.05)",
					borderRadius: "15px",
					padding: "5px 10px",
					marginBottom: "10px",
					borderTop: "1px solid rgba(255,255,255,0.1)",
					boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)",
				}}>
				<span>Free: {balance?.free?.USDT ? balance?.free?.USDT.toFixed(2) : <TextLoading margin="0px 1px" />}</span>
				<span>
					Total: {balance?.total?.USDT ? pnl ? (balance?.total?.USDT + pnl).toFixed(2) : balance?.total?.USDT.toFixed(2) : <TextLoading margin="0px 1px" />}
				</span>
			</motion.div>
			<motion.div
				style={{
					fontSize: "20px",
					display: "flex",
					flexDirection: "column",
					background: "rgba(255,255,255,0.05)",
					borderRadius: "15px",
					borderTop: "1px solid rgba(255,255,255,0.1)",
					padding: "5px 10px",
					marginBottom: "10px",
					boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)",
				}}>
				<motion.div style={{ alignSelf: "center" }}>StopLoss</motion.div>
				<div style={{}}>
					<span style={{ color: "red", marginRight: "5px" }}>
						High:
						{balance && !candleUpdate.isFetching && contents?.stopLoss?.high ? (
							`${contents?.stopLoss?.high?.percentage?.toFixed(2)} %`
						) : (
							<TextLoading margin="0px 1px" />
						)}
					</span>
					<span style={{ color: "green", marginLeft: "5px" }}>
						Low:
						{balance && !candleUpdate.isFetching && contents?.stopLoss?.low ? (
							`${contents?.stopLoss?.low?.percentage?.toFixed(2)} %`
						) : (
							<TextLoading margin="0px 1px" />
						)}
					</span>
				</div>
			</motion.div>
			<motion.div
				style={{
					fontSize: "20px",
					display: "flex",
					flexDirection: "column",
					height: "60px",
					alignContent: "space-between",
					width: "90px",
					padding: "5px 10px",
					marginBottom: "10px",
					borderRadius: "15px",
					borderTop: "1px solid rgba(255,255,255,0.1)",
					background: "rgba(255,255,255,0.05)",
					boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)",
				}}>
				{changeRisk ? (
					<>
						<motion.div
							whileTap={{ scale: 0.95 }}
							layoutId="riskSetting"
							onClick={() => setChangeRisk((cur: any) => !cur)}
							transition={{ type: "spring", duration: 0.2 }}
							style={{
								background: "orange",
								color: "black",
								display: "flex",
								justifyContent: "center",
								borderRadius: "5px",
								width: "35px",
								marginBottom: "5px",
								alignSelf: "center",

								boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)",
							}}>
							{risk}%
						</motion.div>
					</>
				) : (
					<motion.div
						style={{ alignSelf: "center" }}
						initial={{ opacity: 0, scale: 0, y: 10 }}
						animate={{ opacity: 1, scale: 1, y: 0 }}
						exit={{ opacity: 0, scale: 0, y: 10 }}>
						Risk
					</motion.div>
				)}

				{changeRisk ? (
					<motion.div
						ref={componentRef}
						initial={{ opacity: 0, scale: 0, y: 10 }}
						animate={{ opacity: 1, scale: 1, y: 0 }}
						exit={{ opacity: 0, scale: 0, y: 10 }}
						style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)" }}>
						<motion.span
							whileTap={{ scale: 0.95 }}
							onClick={() =>
								setRisk((cur: any) => {
									if (cur === 0.5) {
										return 0.5;
									} else {
										return (cur -= 0.5);
									}
								})
							}
							style={{
								paddingTop: "3px",
								background: "red",
								fontSize: "30px",
								width: "35px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								height: "18px",
								marginRight: "5px",
								fontWeight: 700,
								borderRadius: "3px",
								opacity: 0.8,
								boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)",
							}}>
							-
						</motion.span>
						<motion.span
							whileTap={{ scale: 0.95 }}
							onClick={() =>
								setRisk((cur: any) => {
									if (cur === 5) {
										return 5;
									} else {
										return (cur += 0.5);
									}
								})
							}
							style={{
								paddingTop: "3px",
								background: "green",
								fontSize: "30px",
								width: "35px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								height: "18px",
								marginLeft: "5px",
								fontWeight: 700,
								borderRadius: "3px",
								opacity: 0.8,
								boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)",
							}}>
							+
						</motion.span>
					</motion.div>
				) : (
					<>
						<motion.div
							whileTap={{ scale: 0.95 }}
							transition={{ type: "spring", duration: 0.2 }}
							layoutId="riskSetting"
							onClick={() => setChangeRisk((cur: any) => !cur)}
							style={{
								background: "orange",
								color: "black",
								display: "flex",
								justifyContent: "center",
								borderRadius: "5px",
								width: "35px",
								height: "20px",
								alignSelf: "center",
								boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)",
							}}>
							{risk}%
						</motion.div>
					</>
				)}
			</motion.div>
		</ChartScreenBottomContainer>
	);
};
export default BottomTradeInfo;
