import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import authState from "../atoms/authAtom";
import { authService } from "../firebase";

const Navigator = () => {
	const authUser = useRecoilValue(authState);
	const userId = authUser?.uid;
	return (
		<motion.div
			style={{
				width: "100%",
				height: "60px",
				position: "fixed",
				bottom: "25px",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				// WebkitBackdropFilter: "blur(30px)",
				// backdropFilter: " blur(30px)",
				padding: "0 20px",
			}}>
			<motion.div
				style={{
					borderRadius: "30px",
					color: "rgba(255,255,255,0.8)",
					borderTop: "1px solid rgba(255, 255, 255, 0.1)",
					fontSize: "25px",
					backgroundColor: "rgba(255,255,255,0.06)",
					display: "flex",
					alignItems: "center",
					justifyContent: "space-around",
					width: "100%",
					height: "100%",
					alignSelf: "center",
					boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)",
				}}>
				<Link to={"/"}>
					<i className="fa-solid fa-house"></i>
				</Link>
				<Link to={"/exchanges"}>
					<i className="fa-solid fa-chart-simple"></i>
				</Link>
				<Link to={`/profile`}>
					<i className="fa-solid fa-user"></i>
				</Link>
			</motion.div>
		</motion.div>
	);
};
export default Navigator;
