import { useMotionValue } from "framer-motion";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { IExAccount } from "../../atoms/userAccounts";
import { accountBalance, setupExchange } from "../../api/coinApi";
import WantToDelete from "./WantToDelete";
import SwhowAccount from "./ShowAccount";
import Deleting from "./Deleting";
import { Container } from "../../styleComponents/accountStyle";
const Account = (props: any) => {
	const account: IExAccount = props.account;
	const { reFetchAccount } = props;
	const [userBalance, setUserBalance] = useState<any>(null);
	const [loading, setIsLoading] = useState(false);
	const [wantDelete, setWantDelete] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const exchange = account?.ccxtExchange;

	const x = useMotionValue(0);
	useEffect(() => {
		x.on("change", () => {
			if (x.get() < -200) {
				setWantDelete(true);
			}
		});
	}, [x]);

	const { data } = useQuery(`${account.exchange}/${account.name}Balance`, () => accountBalance(exchange) as any);
	useEffect(() => {
		setUserBalance(data);
	}, [data]);

	return (
		<Container>
			{deleting ? (
				<Deleting key={`deleting${account.name}`} deleteState={[deleting, setDeleting]} account={account} reFetchAccount={reFetchAccount} />
			) : wantDelete ? (
				<WantToDelete account={`delete${account.name}`} state={[wantDelete, setWantDelete]} deleteState={[deleting, setDeleting]} />
			) : (
				<SwhowAccount
					key={`${account.exchange}/${account.name}`}
					account={account}
					loadingState={[loading, setIsLoading]}
					balanceState={[userBalance, setUserBalance]}
					wantDeleteState={[wantDelete, setWantDelete]}
					deleteState={[deleting, setDeleting]}
					exchange={exchange}
				/>
			)}
		</Container>
	);
};
export default Account;
