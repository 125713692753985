import { motion } from "framer-motion";
import styled from "styled-components";
export const RootBackgroundColor: any = {
	"/": "linear-gradient(to right bottom,rgba(46, 127, 216, 1),rgba(89, 120, 232, 1),rgba(138, 106, 239, 1),rgba(188, 81, 234, 1),rgba(235, 18, 216, 1))",
	"/login": "linear-gradient(to right bottom,rgba(46, 135, 216, 1),rgba(0, 172, 238, 1),rgba(0, 202, 208, 1),rgba(0, 225, 134, 1),rgba(160, 235, 18, 1))",
	"/exchanges": "linear-gradient(to right bottom,rgba(46, 135, 216, 1),rgba(0, 172, 238, 1),rgba(0, 202, 208, 1),rgba(0, 225, 134, 1),rgba(160, 235, 18, 1))",
	chart: "linear-gradient(to right bottom, rgba(10,26,33,1),rgba(10,26,33,1),rgba(10,26,33,1),rgba(10,26,33,1),rgba(10,26,33,1)",
};
export const RootWrapper = styled<any>(motion.main)`
	height: 100svh;
	width: 100svw;
	overflow: hidden;
	background-image: ${(props) => (props?.wrapperColor ? props?.wrapperColor : RootBackgroundColor.home)};
`;
