import { ColorType, createChart, CrosshairMode, LastPriceAnimationMode } from "lightweight-charts";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { candleDataConverter, tikerData } from "../../atoms/exchangeAtom";
import { ChartContainer, ChartLoading } from "../../styleComponents/chartComponentStyle";
import WaveLoading from "../WaveLoading";

export const ChartComponent = (props: any) => {
	const [candleStickSeries, setcandleStickSeries] = useState<any>();
	const [volumeBarSeries, setVolumeBarSeries] = useState<any>();
	const [lowLine, setLowLine] = useState<any>();
	const [highLine, setHighLine] = useState<any>();
	const [vwapLine, setVwapLine] = useState<any>();
	const { contents } = useRecoilValueLoadable(candleDataConverter);

	const [resize, setResize] = useState<any>();
	const {
		coin,
		timeFrame,
		chartLoading,
		candleUpdate,
		colors: {
			backgroundColor = "rgba(0,0,0,0)",
			lineColor = "#2962FF",
			textColor = "white",
			areaTopColor = "#2962FF",
			areaBottomColor = "rgba(41, 98, 255, 0.28)",
		} = {},
	} = props;
	const chartContainerRef = useRef<any>();
	const priceScale = coin.precision.price;

	useEffect(() => {
		// candleStickSeries?.setData(contents.candles);
		if (chartLoading) {
			candleStickSeries?.setData(contents.candles);
		} else if (!chartLoading) {
			contents.candles[contents.candles.length - 1] ? candleStickSeries?.update(contents.candles[contents.candles.length - 1]) : console.log("updateFailed");
		}
		vwapLine && contents.vwap ? vwapLine.update({ time: contents.candles[contents.candles.length - 1].time, value: contents.vwap }) : console.log();

		if (contents.candles[contents.candles.length - 1].low > contents.low.value) {
			lowLine?.update({ time: contents.candles[contents.candles.length - 1].time, value: contents.low.value });
		} else {
			lowLine?.update(contents.low);
		}
		if (contents.candles[contents.candles.length - 1].high < contents.high.value) {
			highLine?.update({ time: contents.candles[contents.candles.length - 1].time, value: contents.high.value });
		} else {
			highLine?.update(contents.high);
		}
		contents.low.time > contents.high.time
			? candleStickSeries?.setMarkers([contents.high, contents.low])
			: candleStickSeries?.setMarkers([contents.low, contents.high]);

		// volumeBarSeries?.setData(
		// 	contents.candles.map((candle: any) => {
		// 		return {
		// 			time: candle.time,
		// 			value: candle.volume,
		// 			color: candle.open < candle.close ? "#50e1558a" : "#ff33337b",
		// 		};
		// 	})
		// );
		if (chartLoading) {
			volumeBarSeries?.setData(
				contents.candles.map((candle: any) => {
					return {
						time: candle.time,
						value: candle.volume,
						color: candle.open < candle.close ? "#50e1558a" : "#ff33337b",
					};
				})
			);
		} else if (!chartLoading) {
			volumeBarSeries?.update({
				time: contents.candles[contents.candles.length - 1].time,
				value: contents.candles[contents.candles.length - 1].volume,
				color: contents.candles[contents.candles.length - 1].open < contents.candles[contents.candles.length - 1].close ? "#50e1558a" : "#ff33337b",
			});
		}
	}, [contents.candles]);

	useEffect(() => {
		const handleResize = () => {
			chart.applyOptions({ width: chartContainerRef.current.clientWidth, height: chartContainerRef.current.clientHieght });
		};
		const chart = createChart(chartContainerRef.current, {
			layout: {
				background: { type: ColorType.Solid, color: backgroundColor },
				textColor,
			},
			width: chartContainerRef.current.clientWidth,
			height: chartContainerRef.current.clientHieght,
			timeScale: {
				borderVisible: false,
				borderColor: "#fff000",
				visible: true,
				timeVisible: true,
				minBarSpacing: 0.0,
			},
			grid: {
				vertLines: {
					color: "rgba(255,255,255,0.03)",
				},
				horzLines: {
					color: "rgba(255,255,255,0.03)",
				},
			},
			crosshair: {
				mode: CrosshairMode.Normal,
			},
			rightPriceScale: {
				scaleMargins: {
					top: 0.15,
					bottom: 0.15,
				},
				borderVisible: false,
			},
		});
		chart.timeScale().applyOptions({
			barSpacing: 7,
		});
		setResize(chart as any);

		const candleSeries = chart.addCandlestickSeries({
			upColor: "#30b434",
			downColor: "#d40b0b",
			borderDownColor: "#d40b0b",
			borderUpColor: "#30b434",
			wickDownColor: "#62697a",
			wickUpColor: "#62697a",
			priceFormat: {
				type: "price",
				precision: String(priceScale).length - 2,
				minMove: priceScale,
			},
		});
		const volumeSeries = chart.addHistogramSeries({
			color: "#385263",
			priceFormat: {
				type: "volume",
			},
			priceScaleId: "volume",
			visible: true,
			priceLineVisible: false,
			lastValueVisible: false,
		});

		const lowLineSeries = chart.addAreaSeries({
			lastPriceAnimation: LastPriceAnimationMode.Continuous,
			lineWidth: 2,
			priceLineVisible: false,
			lastValueVisible: false,
			topColor: "rgba(0,0,0,0)",
			bottomColor: "rgba(0,0,0,0)",
		});

		setLowLine(lowLineSeries);
		const highLineSeries = chart.addAreaSeries({
			lastPriceAnimation: LastPriceAnimationMode.Continuous,
			invertFilledArea: true,
			lineWidth: 2,
			priceLineVisible: false,
			priceLineColor: "red",
			lineColor: "rgba(225, 0, 0, 0.602)",
			topColor: "rgba(0,0,0,0)",
			bottomColor: "rgba(0,0,0,0)",
			lastValueVisible: false,
		});

		const vwapLineSeries = chart.addLineSeries({
			title: "VWAP",
			priceLineVisible: false,
			lastPriceAnimation: LastPriceAnimationMode.Continuous,
		});
		setVwapLine(vwapLineSeries);
		vwapLineSeries.setData([{ time: contents.candles[contents.candles.length - 1].time, value: contents.vwap }]);

		setHighLine(highLineSeries);
		lowLineSeries.setData([contents.low]);
		highLineSeries.setData([contents.high]);

		candleSeries.setData(contents.candles);

		if (contents.candles[contents.candles.length - 1].low > contents.low.value) {
			lowLineSeries.update({ time: contents.candles[contents.candles.length - 1].time, value: contents.low.value });
		}

		if (contents.candles[contents.candles.length - 1].high < contents.high.value) {
			highLineSeries.update({ time: contents.candles[contents.candles.length - 1].time, value: contents.high.value });
		}
		contents.low.time > contents.high.time ? candleSeries?.setMarkers([contents.high, contents.low]) : candleSeries?.setMarkers([contents.low, contents.high]);
		volumeSeries.setData(
			contents.candles.map((candle: any) => {
				return {
					time: candle.time,
					value: candle.volume,
					color: candle.open < candle.close ? "#50e1558a" : "#ff33337b",
				};
			})
		);
		candleSeries.priceScale().applyOptions({
			ticksVisible: true,
		});
		volumeSeries.priceScale().applyOptions({
			ticksVisible: false,
			scaleMargins: {
				top: 0.75,
				bottom: 0.0,
			},
		});
		setcandleStickSeries(candleSeries);
		setVolumeBarSeries(volumeSeries);
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
			chart.remove();
		};
	}, [timeFrame, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor]);

	return <ChartContainer key={timeFrame} ref={chartContainerRef} initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: 10 }} />;
};

export function Chart(props: any) {
	const { coin, timeFrame, candleUpdate, chartLoading } = props;
	const { contents } = useRecoilValueLoadable(candleDataConverter);

	return !candleUpdate.isFetching && contents.candles ? (
		<ChartComponent key={`${coin.symbol}/${timeFrame}`} {...props} timeFrame={timeFrame} chartLoading={chartLoading}></ChartComponent>
	) : (
		<ChartLoading initial={{ opacity: 0, scale: 0 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0, scale: 0 }}>
			<WaveLoading />
		</ChartLoading>
	);
}
