import { motion } from "framer-motion";
import styled from "styled-components";

export const ChartContainer = styled(motion.div)`
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;
export const ChartLoading = styled(motion.div)`
	font-size: 60px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
`;
