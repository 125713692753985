import React, { useEffect, useRef, useState } from "react";

import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { tradeObjects } from "../api/coinApi";
import { candleDataConverter, coinForTrade, percentageState } from "../atoms/exchangeAtom";
import { accountForTradeState, userAccountState } from "../atoms/userAccounts";
import { Chart } from "../Components/ChartComponents/ChartComponent";

import {
	HighPriceInfo,
	FundingInfo,
	LowPriceInfo,
	VolumeInfo,
	ChartScreenChartContainer,
	ChartScreenTopInfoLeft,
	ChartScreenTopInfo,
	ChartScreenRightPriceInfo,
	ChartScreenWrapper,
	ChartScreenTopInfoLeftTop,
	ChartScreenTopInfo_left_bottom,
	TradeButtonContainer,
} from "../styleComponents/chartScreenStyle";
import TimeFrameSelector from "../Components/ChartComponents/TimeFrameSelector";
import Header from "../Components/Header";
import Navigator from "../Components/Navigator";
import ChartDataComponent from "../Components/ChartComponents/ChartDataComponent";

import { AnimatePresence, motion } from "framer-motion";
import TradeComponent from "../Components/ChartComponents/TradeComponent";

import PositionComponent from "../Components/ChartComponents/PositionComponent";
import BottomTradeInfo from "../Components/ChartComponents/BottomTradeInfo";
import styled from "styled-components";
import TextLoading from "../Components/TextLoading";
import { RootBackgroundColor } from "../styleComponents/generalStyles";

const AlertWindow = styled(motion.div)`
	box-sizing: border-box;
	padding: 20px 30px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	font-size: 25px;
	color: white;
	max-width: 100%;
	background: rgba(255, 255, 255, 0.1);
	z-index: 5;
	border-radius: 20px;
	box-shadow: 9px 16px 16px hsl(0deg 0% 0% / 0.25);
	line-height: 0.8;
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
	span {
		width: 100%;
		word-wrap: break-word;
	}
`;

const ChartScreen = () => {
	const { coinId, accountId, exchangeId } = useParams();
	const accounts = useRecoilValue(userAccountState);
	const account = accounts.find((item) => item.exchange === exchangeId && item.name === accountId);
	const exchange = account?.ccxtExchange;
	const coin: any = useRecoilValueLoadable(coinForTrade).contents;
	const location = useLocation().pathname;

	//data to frontend
	const [priceInfo, setPriceInfo] = useState<any>();
	const setPercentage = useSetRecoilState(percentageState);
	const [positions, setPositions] = useState<any>();
	const [allPositions, setAllPositions] = useState<any>();
	const [togglePositions, setTogglePositions] = useState(false);
	const [watchPosition, setWatchPosition] = useState(false);

	//data from chart
	const [risk, setRisk] = useState<number>(1.5);
	const [changeRisk, setChangeRisk] = useState(false);
	const [timeFrame, setTimeFrame] = useState<string>("30m");
	const [moreTF, setMoreTF] = useState(false);

	//data for chart

	const [balance, setBalance] = useState<any>();
	const { contents } = useRecoilValueLoadable(candleDataConverter);
	const [tradeConfirm, setTradeConfirm] = useState<any>(null);

	const [chartLoading, setChartLoading] = useState(true);
	const [tradeInfo, setTradeInfo] = useState<any>();
	const [pnl, setPnl] = useState();

	const candleUpdate = useQuery(
		`${coin.symbol}/${timeFrame}/chartUpdate`,
		() => {
			return exchange.fetchOHLCV(coin.symbol, timeFrame);
		},
		{
			enabled: true,
			staleTime: Infinity,
			refetchOnWindowFocus: "always",
		}
	);

	useEffect(() => {
		candleUpdate.refetch();
		candleUpdate.remove();
	}, [location]);

	useEffect(() => {
		candleUpdate.refetch();
	}, [timeFrame]);

	const componentRef = useRef<any>(null);
	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (componentRef.current && !componentRef.current.contains(event.target)) {
				setChangeRisk(false);
			}
		};
		document.addEventListener("touchstart", handleClickOutside);
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("touchstart", handleClickOutside);
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [componentRef]);

	useEffect(() => {
		!priceInfo
			? setPriceInfo({ lastPrice: contents?.lastPrice, color: "white" } as any)
			: contents?.lastPrice
			? contents?.lastPrice !== priceInfo
				? setPriceInfo(
						priceInfo.lastPrice < contents?.lastPrice
							? { lastPrice: contents?.lastPrice, color: "rgba(44	,170,	39,1)" }
							: { lastPrice: contents?.lastPrice, color: "rgba(232	,58,	19	,1)	" }
				  )
				: console.log()
			: console.log();
		priceInfo ? setPercentage({ ...priceInfo, symbol: coin.symbol, percentage: contents?.percentage }) : console.log();
		if (contents.candles) {
			setTradeInfo({
				long: tradeObjects("long", contents, risk, balance),
				short: tradeObjects("short", contents, risk, balance),
			});
		}
	}, [contents]);

	useEffect(() => {
		if (contents.candles) {
			setTradeInfo({
				long: tradeObjects("long", contents, risk, balance),
				short: tradeObjects("short", contents, risk, balance),
			});
		}
	}, [risk]);

	const time: any = {
		"1m": "1",
		"5m": "5",
		"15m": "15",
		"30m": "30",
		"1h": "60",
		"4h": "240",
		"1d": "D",
		"1w": "W",
		"1M": "M",
	};
	const dataForChart = { exchange, coin, timeFrame, setBalance };
	const fundingRateTime: number = (contents?.info?.nextFundingTime - exchange.milliseconds()) / 1000;
	// const fundingRateHour: number = parseInt(String(fundingRateTime / 3600));
	// const fundingRateMins: number = parseInt(String((fundingRateTime % 3600) / 60));

	return (
		<ChartScreenWrapper
			onClick={() => {
				moreTF ? setMoreTF(false) : console.log();
			}}
			initial={{ backgroundImage: RootBackgroundColor.chart }}
			animate={{ backgroundImage: RootBackgroundColor.chart }}
			exit={{ backgroundImage: RootBackgroundColor.chart }}>
			<Header
				backdropFilter="none"
				WebkitBackdropFilter="none"
				background="none"
				boxShadow="none"
				pageTitle={coin.symbol}
				percentage={balance && !candleUpdate.isFetching ? contents?.percentage : null}
			/>
			<AnimatePresence>
				{tradeConfirm ? (
					<motion.div
						key={"alert"}
						style={{
							overflow: "hidden",
							position: "absolute",
							boxSizing: "border-box",
							width: "100%",
							paddingTop: "10%",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}>
						<AlertWindow
							key={"alertDetail"}
							initial={{ opacity: 0, y: 100 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, x: 300 }}>
							{tradeConfirm?.error ? (
								<motion.span style={{ color: "red" }}>{`${tradeConfirm?.from}:${tradeConfirm?.error}`}</motion.span>
							) : null}
							{tradeConfirm?.complete?.order ? (
								<motion.span style={{}}> {tradeConfirm?.complete?.order} </motion.span>
							) : null}
							{tradeConfirm?.complete?.stopLoss ? (
								<motion.span style={{}}> {tradeConfirm?.complete?.stopLoss} </motion.span>
							) : null}
							{tradeConfirm?.complete?.partialClose ? (
								<motion.span style={{}}>{tradeConfirm?.complete?.partialClose}</motion.span>
							) : null}
							{tradeConfirm?.complete?.marketClose ? (
								<motion.span style={{}}> {tradeConfirm?.complete?.marketClose} </motion.span>
							) : null}
						</AlertWindow>
					</motion.div>
				) : null}
			</AnimatePresence>
			<ChartScreenTopInfo
				initial={{ opacity: 0.5, scale: 0, y: 10 }}
				animate={{ opacity: 1, scale: 1, y: 0 }}
				exit={{ opacity: 0.5, scale: 0, y: 10 }}>
				<motion.span
					style={{ color: "orange", margin: "none", fontSize: "18px", position: "absolute", marginTop: "-12px" }}>
					{account?.exchange}
				</motion.span>
				<ChartScreenTopInfoLeft>
					<ChartScreenTopInfoLeftTop
						initial={{ opacity: 0.5, scale: 0, y: 5 }}
						animate={{ opacity: 1, scale: 1, y: 0, transition: { type: "spring" } }}
						exit={{ opacity: 0.5, scale: 0, y: 5 }}>
						{contents?.lastPrice && balance && !candleUpdate.isFetching ? (
							<motion.span style={{ fontWeight: 700, fontSize: "45px", color: priceInfo?.color }}>
								{contents.lastPrice}
							</motion.span>
						) : (
							<motion.span style={{ fontWeight: 700, fontSize: "45px", color: priceInfo?.color }}>
								<TextLoading margin="3px" />
							</motion.span>
						)}
					</ChartScreenTopInfoLeftTop>
					<ChartScreenTopInfo_left_bottom>
						<TimeFrameSelector
							moreTfState={{ moreTF, setMoreTF }}
							timeFrameState={{ timeFrame, setTimeFrame }}
							initial={{ opacity: 0.5, scale: 0, y: 5 }}
							animate={{ opacity: 1, scale: 1, y: 0, transition: { type: "spring" } }}
							exit={{ opacity: 0.5, scale: 0, y: 5 }}
						/>
					</ChartScreenTopInfo_left_bottom>
				</ChartScreenTopInfoLeft>
				<ChartScreenRightPriceInfo
					initial={{ opacity: 0.5, scale: 0, y: 10 }}
					animate={{ opacity: 1, scale: 1, y: 0 }}
					exit={{ opacity: 0.5, scale: 0, y: 10 }}>
					<HighPriceInfo>
						24H High
						{exchangeId === "bybit" ? (
							<span>
								{contents?.info?.highPrice24h && balance && !candleUpdate.isFetching ? (
									`${contents?.info?.highPrice24h}`
								) : (
									<TextLoading margin="1px" fontSize="18px" />
								)}
							</span>
						) : null}
						{exchangeId === "bitget" ? (
							<span>
								{contents?.info?.high24h && balance && !candleUpdate.isFetching ? (
									`${contents?.info?.high24h}`
								) : (
									<TextLoading margin="1px" fontSize="18px" />
								)}
							</span>
						) : null}
					</HighPriceInfo>
					<LowPriceInfo>
						24H Low
						{exchangeId === "bybit" ? (
							<span>
								{contents?.info?.lowPrice24h && balance && !candleUpdate.isFetching ? (
									`${contents?.info?.lowPrice24h}`
								) : (
									<TextLoading margin="1px" fontSize="18px" />
								)}
							</span>
						) : null}
						{exchangeId === "bitget" ? (
							<span>
								{contents?.info?.low24h && balance && !candleUpdate.isFetching ? (
									`${contents?.info?.low24h}`
								) : (
									<TextLoading margin="1px" fontSize="18px" />
								)}
							</span>
						) : null}
					</LowPriceInfo>
					<VolumeInfo>
						24H Volume
						{exchangeId === "bybit" ? (
							<span>
								{contents?.info?.volume24h && balance && !candleUpdate.isFetching ? (
									`${Number(contents?.info?.volume24h).toFixed(2)}`
								) : (
									<TextLoading margin="1px" fontSize="18px" />
								)}
							</span>
						) : null}
						{exchangeId === "bitget" ? (
							<span>
								{contents?.info?.baseVolume && balance && !candleUpdate.isFetching ? (
									`${Number(contents?.info?.baseVolume).toFixed(2)}`
								) : (
									<TextLoading margin="1px" fontSize="18px" />
								)}
							</span>
						) : null}
					</VolumeInfo>
					<FundingInfo>
						FundingRate
						<span>
							{contents?.info?.fundingRate && balance && !candleUpdate.isFetching ? (
								`${(contents?.info?.fundingRate * 100).toFixed(4)}%`
							) : (
								<TextLoading margin="1px" fontSize="18px" />
							)}
						</span>
					</FundingInfo>
				</ChartScreenRightPriceInfo>
			</ChartScreenTopInfo>

			<ChartScreenChartContainer id={`${coinId}/${timeFrame}`} onTouchStart={() => setMoreTF(false)}>
				<Chart
					balance={balance}
					account={account}
					coin={coin}
					timeFrame={timeFrame}
					candleUpdate={candleUpdate}
					chartLoading={chartLoading}
				/>
				{!watchPosition ? (
					<motion.button
						exit={{ opacity: 0.5, scale: 0, y: 10 }}
						key="positioninfoButton"
						style={{
							fontSize: "11px",
							display: "flex",
							color: "orange",
							padding: "0 20px",
							width: "100%",
							justifyContent: "flex-end",
							alignItems: "center",
							border: "none",
							background: "none",
						}}>
						<span onClick={() => setWatchPosition((cur) => !cur)}>
							<i style={{ padding: "0 5px" }} className="fa-solid fa-table-list fa-2xs"></i>
							<span>Positions</span>
						</span>
					</motion.button>
				) : (
					<motion.button
						key="tradeinfoButton"
						exit={{ opacity: 0.5, scale: 0, y: 10 }}
						style={{
							fontSize: "11px",
							display: "flex",
							color: "orange",
							padding: "0 20px",
							width: "100%",
							justifyContent: "space-between",
							alignItems: "center",
							border: "none",
							background: "none",
						}}>
						{!togglePositions ? (
							<>
								<span onClick={() => setTogglePositions((cur) => !cur)}>Current</span>
							</>
						) : (
							<>
								<span onClick={() => setTogglePositions((cur) => !cur)}>All Contracts({allPositions?.length})</span>
							</>
						)}

						<span onClick={() => setWatchPosition((cur) => !cur)}>
							<i style={{ padding: "0 5px" }} className="fa-solid fa-money-bill-wave fa-2xs"></i>
							<span>Trade</span>
						</span>
					</motion.button>
				)}
			</ChartScreenChartContainer>
			<motion.div
				style={{
					display: "flex",
					justifyContent: "center",
					alignContent: "flex-start",
					flexDirection: "column",
					height: "20%",
					marginBottom: "100px",
				}}>
				{!watchPosition ? (
					<motion.div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
						<BottomTradeInfo
							key={"tradeInfo"}
							data={{ pnl, balance, candleUpdate, contents, changeRisk, setChangeRisk, componentRef, risk, setRisk }}
						/>
						{!watchPosition && contents?.candles && balance && !candleUpdate.isFetching ? (
							<TradeComponent
								key="tradeComponent"
								exchange={exchange}
								coin={coin}
								tradeInfo={tradeInfo}
								setTradeConfirm={setTradeConfirm}
								tradeConfirm={tradeConfirm}
							/>
						) : (
							<TradeButtonContainer></TradeButtonContainer>
						)}
					</motion.div>
				) : (
					<motion.div style={{ height: "100%" }}>
						<PositionComponent
							key={"positionInfo"}
							data={
								!togglePositions
									? { positions, balance, candleUpdate, exchange, setTradeConfirm }
									: { positions: allPositions, balance, candleUpdate, exchange, setTradeConfirm }
							}
						/>
					</motion.div>
				)}
			</motion.div>

			<Navigator />
			{candleUpdate.data && !candleUpdate.isFetching ? (
				<ChartDataComponent
					setAllPositions={setAllPositions}
					data={dataForChart}
					candleData={candleUpdate.data}
					setChartLoading={setChartLoading}
					setPositions={setPositions}
					setPnl={setPnl}
				/>
			) : (
				<></>
			)}
		</ChartScreenWrapper>
	);
};

export default ChartScreen;
