import ReactDOM from "react-dom/client";
// import reportWebVitals from './reportWebVitals';
import GlobalStyle from "./ResetCss";
import { RecoilEnv, RecoilRoot } from "recoil";
import router from "./Router";
import { RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
//@ts-ignore
serviceWorkerRegistration.register();
export const ccxt = window.ccxt.pro;

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const queryClient = new QueryClient();

root.render(
	// <React.StrictMode>
	<RecoilRoot>
		<QueryClientProvider client={queryClient}>
			<GlobalStyle />
			<RouterProvider router={router} />
			{/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
		</QueryClientProvider>
	</RecoilRoot>

	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
