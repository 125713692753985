import { motion } from "framer-motion";
import styled from "styled-components";
import { LoadingWrapper } from "../styleComponents/loadingStyle";

const Dots = styled(motion.span)`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 15px;
	border-radius: 100%;
	box-shadow: 9px 16px 16px hsl(0deg 0% 0% / 0.6);
`;

const animationContainer = {
	initial: {
		transition: {
			staggerChildren: 0.2,
		},
	},
	animate: {
		transition: {
			staggerChildren: 0.2,
		},
	},
};
const waveCircle = {
	initial: {
		y: "0%",
	},
	animate: {
		y: ["100%", "0%", "100%"],
		opacity: [1, 0.5, 1],
		scale: [0.2, 1, 0.2],
	},
};
const DotTransition = {
	duration: 1,
	repeat: Infinity,
	ease: "easeInOut",
};
const WaveLoading = (props: any) => {
	const { position } = props;
	return (
		<LoadingWrapper style={{ position, alignSelf: "center" }} variants={animationContainer} initial="initial" animate="animate">
			<Dots variants={waveCircle} transition={DotTransition}>
				<i className="fa-solid fa-circle"></i>
			</Dots>
			<Dots variants={waveCircle} transition={DotTransition}>
				<i className="fa-solid fa-circle"></i>
			</Dots>
			<Dots variants={waveCircle} transition={DotTransition}>
				<i className="fa-solid fa-circle"></i>
			</Dots>
			<Dots variants={waveCircle} transition={DotTransition}>
				<i className="fa-solid fa-circle"></i>
			</Dots>
			<Dots variants={waveCircle} transition={DotTransition}>
				<i className="fa-solid fa-circle"></i>
			</Dots>
		</LoadingWrapper>
	);
};

export default WaveLoading;
