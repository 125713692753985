import { motion } from "framer-motion";
import styled from "styled-components";
import { exchangeLogo } from "../styleComponents/exchangeStyle";
import { getAllCoins, marketClose } from "../api/coinApi";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { coinForTrade } from "../atoms/exchangeAtom";
import { accountForTradeState } from "../atoms/userAccounts";
import { useSetRecoilState } from "recoil";

const PositionsContainer = styled(motion.div)`
	display: flex;
	position: relative;
	flex-direction: column;
	width: 90%;
	max-height: 70%;
	color: white;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 20px;
	box-shadow: 9px 16px 16px hsl(0deg 0% 0% / 0.25);
	padding: 20px;
	overflow-y: scroll;
`;
const BlurWrapper = styled(motion.div)`
	position: absolute;
	top: 0;
	box-sizing: border-box;
	height: 100%;
	width: 100%;
	backdrop-filter: blur(5px);
	display: flex;
	align-items: center;
	justify-content: center;
	-webkit-backdrop-filter: blur(5px);
`;
const PositionList = styled(motion.li)`
	width: 100%;
	background: rgba(255, 255, 255, 0.2);
	border-radius: 15px;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	box-shadow: 9px 16px 16px hsl(0deg 0% 0% / 0.25);
	margin: 10px 0%;
`;
const PositionListTop = styled(motion.div)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 15px;
`;
const PositionListBottom = styled(motion.div)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 15px;
	div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		line-height: 0.8;
	}
`;
const PositionListTopLeft = styled(motion.div)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	line-height: 0.8;
`;
const PositionListTopRight = styled(motion.div)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: end;
	line-height: 0.8;
`;

const Positions = (props: any) => {
	const { setTogglePositions, accountBalance } = props;
	const [tradeConfirm, setTradeConfirm] = useState<any>(null);
	const [inform, setInform] = useState(false);
	const setAccount = useSetRecoilState(accountForTradeState);
	const setCoinForTrade = useSetRecoilState(coinForTrade);
	const navigate = useNavigate();
	useEffect(() => {
		(async () => {})();
	}, []);
	return (
		<BlurWrapper onClick={() => setTogglePositions((cur: boolean) => !cur)}>
			<PositionsContainer layoutId="positions">
				{accountBalance.map((account: any) => {
					return account.positions.length > 0 ? (
						<motion.div key={`${account.name}/${account.exchange}/positions`}>
							<motion.span style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
								<motion.span style={{ display: "flex", textAlign: "center", alignItems: "center", fontSize: "20px" }}>
									<motion.img style={{ aspectRatio: 1 / 1, width: "20px", marginRight: "5px" }} src={exchangeLogo[account.exchange]} />
									{account.name}
								</motion.span>
							</motion.span>
							<motion.ul style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
								{account.positions.map((position: any) => {
									return (
										<PositionList>
											<motion.div style={{ display: "flex", justifyContent: "flex-end", margin: "5px 10px" }}>
												<motion.span
													onClick={async () => {
														const allCoins = await getAllCoins(account.ccxtExchange);
														const coin = await allCoins.find((coin: any) => coin.symbol === position.symbol);
														if (coin?.info?.leverageFilter?.maxLeverage) {
															setCoinForTrade(coin);
														} else {
															await account.ccxtExchange.fetchLeverage(coin.symbol).then((response: any) => {
																setCoinForTrade({ ...coin, crossMarginLeverage: response.data.crossMarginLeverage });
															});
														}
														setAccount(account);
														navigate(`/${account.exchange}/${account.name}/${coin?.baseId}`);
													}}>
													&rarr; Go to chart
												</motion.span>
											</motion.div>
											<PositionListTop>
												<PositionListTopLeft>
													<motion.span>
														<motion.span style={{ fontSize: "25px" }}>{position.symbol}</motion.span>
														<motion.span style={position.side === "short" ? { fontSize: "16px", color: "red" } : { fontSize: "16px", color: "green" }}>
															{" "}
															{position.side}
														</motion.span>
													</motion.span>
													<motion.span style={{ fontSize: "16px" }}>
														<motion.span>{position.marginMode} </motion.span>
														<motion.span>{position.leverage}x</motion.span>
													</motion.span>
												</PositionListTopLeft>
												<PositionListTopRight>
													<motion.span style={{ fontSize: "16px", color: "orange" }}>Unrealized P&L</motion.span>
													<motion.span
														style={
															Number(position.unrealizedPnl) === 0
																? { color: "white", fontSize: "25px" }
																: position.unrealizedPnl > 0
																? { color: "green", fontSize: "25px" }
																: { color: "red", fontSize: "25px" }
														}>{`${position.unrealizedPnl.toFixed(2)}(${position.percentage.toFixed(2)}%)`}</motion.span>
												</PositionListTopRight>
											</PositionListTop>
											<PositionListBottom>
												<motion.div>
													<motion.span style={{ fontSize: "16px", color: "orange" }}>Position Size</motion.span>
													<motion.span style={{ fontSize: "25px" }}>{position.contracts}</motion.span>
												</motion.div>
												<motion.div>
													<motion.span style={{ fontSize: "16px", color: "orange" }}>EntryPrice</motion.span>
													<motion.span style={{ fontSize: "25px" }}>{position.entryPrice.toFixed(2)}</motion.span>
												</motion.div>
												<motion.button
													whileTap={{ scale: 0.95 }}
													onClick={async () => {
														setInform(true);
														await marketClose(account.ccxtExchange, position.info.positionIdx, position.symbol, position.contracts, position.side).then(
															(response: any) => {
																setInform(false);
																setTradeConfirm(response);
																setTimeout(() => setTradeConfirm(null), 5000);
															}
														);
													}}
													disabled={inform}
													style={{
														background: "rgba(0,0,0,0.4)",
														border: "1px solid rgba(255,255,255,0.1)",
														padding: "10px",
														borderRadius: "10px",
														color: "white",
														outline: "none",
														fontWeight: 500,
														boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)",
													}}>
													Close
												</motion.button>
											</PositionListBottom>
										</PositionList>
									);
								})}
							</motion.ul>
						</motion.div>
					) : (
						<motion.div></motion.div>
					);
				})}
			</PositionsContainer>
		</BlurWrapper>
	);
};
export default Positions;
