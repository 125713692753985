import Header from "../Components/Header";
import { Container, RootBackgroundColor, Wrapper } from "../styleComponents/generalStyles";
import Navigator from "../Components/Navigator";
import { motion } from "framer-motion";
import styled from "styled-components";

const Settings = styled(motion.ul)`
	background-color: rgba(255, 255, 255, 0.2);
	height: 100%;
	border-radius: 30px 30px 0 0;
	padding-top: 20px;
`;
const Setting = styled(motion.li)`
	height: 50px;
	padding: 0 20px;
	border-bottom: 1px solid black;
	display: flex;
	align-items: center;
	font-size: 35px;
`;

const Profile = (props: any) => {
	return (
		<Wrapper wrapperColor={RootBackgroundColor.chart} transition={{ type: "keyframes" }}>
			<Header pageTitle={"Profile"} />
			<Container style={{ paddingTop: "65px", marginBottom: "120px" }}>
				<motion.div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
					<span style={{ width: "130px", height: "130px", background: "white", marginTop: "30px", borderRadius: "100%", marginBottom: "30px" }}></span>
				</motion.div>
				<Settings>
					<Setting>
						<div></div>
					</Setting>
				</Settings>
			</Container>
			<Navigator />
		</Wrapper>
	);
};

export default Profile;
