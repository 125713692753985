import { motion } from "framer-motion";
import { useState } from "react";
import styled from "styled-components";
import { LoadingText } from "../../styleComponents/loadingStyle";
import {
	PositionList,
	PositionListBottom,
	PositionPnL,
	PositionLeverage,
	PositionListTop,
	PositionListTopLeft,
	PositionListTopRight,
	PositionLists,
	PositionPnLTitle,
	PositionSymbolName,
} from "../../styleComponents/positionStyle";
import TextLoading from "../TextLoading";
import WaveLoading from "../WaveLoading";
import { CheckButton, CancelButton } from "../../styleComponents/accountStyle";

const PositionInfoCard = (props: any) => {
	const { position, marketClose, setTradeConfirm, exchange } = props.data;
	const [inform, setInform] = useState(false);
	const [closeCheck, setCloseCheck] = useState(false);
	return (
		<PositionList
			key={`${position?.symbol}/${position?.side}`}
			initial={{ opacity: 0, y: -100 }}
			animate={inform ? { opacity: 0.5, y: 0 } : { opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: -100 }}>
			<PositionListTop>
				<PositionListTopLeft>
					<PositionSymbolName>
						<motion.span>{position?.symbol} </motion.span>
						<motion.span style={{ color: position.side === "short" ? "red" : "green", fontSize: "18px" }}> {position?.side}</motion.span>
					</PositionSymbolName>
					<PositionLeverage>
						<motion.span style={{ fontSize: "18px" }}>{`${position?.marginMode} ${position?.leverage}x`}</motion.span>
					</PositionLeverage>
				</PositionListTopLeft>
				<PositionListTopRight>
					<PositionPnLTitle>Unrealized P&L</PositionPnLTitle>
					<PositionPnL style={{ color: position.unrealizedPnl > 0 ? "rgba(43,170,39,1)" : "red" }}>
						<motion.span>{position?.unrealizedPnl?.toFixed(2)}</motion.span>
						<motion.span>({position?.percentage?.toFixed(2)}%)</motion.span>
					</PositionPnL>
				</PositionListTopRight>
			</PositionListTop>
			<PositionListBottom>
				<motion.span>
					<motion.span style={{ color: "orange", fontSize: "16px" }}>Position Size</motion.span>
					<motion.span>{position?.contracts}</motion.span>
				</motion.span>
				<motion.span>
					<motion.span style={{ color: "orange", fontSize: "16px" }}>Entry Price</motion.span>
					<motion.span>{position?.entryPrice.toFixed(2)}</motion.span>
				</motion.span>

				{closeCheck ? (
					inform ? (
						<TextLoading display="felx" flexDirection="row" margin="0 3px" />
					) : (
						<motion.span
							style={{
								display: "flex",
								width: "100px",
								justifyContent: "space-around",
								flexDirection: "row",
								background: "rgba(255,255,255,0.2)",
								padding: "5px 10px",
								borderRadius: "15px",
								borderTop: "1px solid rgba(255, 255, 255, 0.1)",
								boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)",
							}}>
							<CheckButton
								style={{}}
								disabled={inform}
								whileTap={{ scale: 0.95 }}
								onClick={async () => {
									setInform(true);
									await marketClose(exchange, position.info.positionIdx, position.symbol, position.contracts, position.side).then((response: any) => {
										setInform(false);
										setTradeConfirm(response);
										setTimeout(() => setTradeConfirm(null), 5000);
									});
									setCloseCheck(false);
								}}>
								<i className="fa-solid fa-check"></i>
							</CheckButton>
							<CancelButton whileTap={{ scale: 0.95 }} disabled={inform} onClick={() => setCloseCheck(false)}>
								<i className="fa-solid fa-xmark"></i>
							</CancelButton>
						</motion.span>
					)
				) : (
					<motion.span>
						<motion.button
							whileTap={{ scale: 0.95 }}
							onClick={() => setCloseCheck(true)}
							disabled={inform}
							style={{
								background: "rgba(0,0,0,0.4)",
								border: "1px solid rgba(255,255,255,0.1)",
								padding: "10px",
								borderRadius: "10px",
								color: "white",
								outline: "none",
								fontWeight: 700,
								boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)",
							}}>
							Market Close
						</motion.button>
					</motion.span>
				)}
			</PositionListBottom>
		</PositionList>
	);
};

export default PositionInfoCard;
