import { motion } from "framer-motion";
import styled from "styled-components";

export const HeaderWrapper = styled<any>(motion.div)`
	width: 100%;
	height: 65px;
	position: fixed;
	padding: 3%;
	color: white;
	display: flex;
	top: 0;
	z-index: 10;

	justify-content: space-between;

	-webkit-backdrop-filter: blur(30px);
	backdrop-filter: blur(30px);
`;
export const HeaderPageTitle = styled(motion.h1)`
	font-size: 30px;
	display: flex;
	align-items: center;
	text-align: center;
	font-weight: 700;
	span {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 10px;
	}
`;
export const HeaderNav = styled(motion.nav)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	a,
	span {
		font-size: 25px;
		margin-right: 10px;
		color: rgba(255, 255, 255, 0.5);
	}
	a:hover,
	span:hover {
		border-bottom: 1px solid rgba(255, 255, 255, 0.5);
		color: white;
	}
`;
