import { useRecoilValue } from "recoil";
import { filteredUserAccountState } from "../atoms/userAccounts";
import { useEffect } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { exchangeLogo } from "../styleComponents/exchangeStyle";

const BalanceDetail = styled(motion.div)`
	display: flex;
	position: relative;
	flex-direction: column;
	width: 80%;
	height: auto;
	color: white;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 20px;
	box-shadow: 9px 16px 16px hsl(0deg 0% 0% / 0.25);
	padding: 20px;
	div {
	}
`;
const BlurWrapper = styled(motion.div)`
	position: absolute;
	top: 0;
	box-sizing: border-box;
	height: 100%;
	width: 100%;
	backdrop-filter: blur(5px);
	display: flex;
	align-items: center;
	justify-content: center;
	-webkit-backdrop-filter: blur(5px);
`;

const ExchangeBalance = (props: any) => {
	const { setToggleBalance, accountBalance } = props;
	const balance = accountBalance
		.map((account: any) => account.balance)
		.reduce((a: number, b: number) => a + b, 0)
		.toFixed(2);
	const totalPnl = accountBalance
		.map((account: any) => account.positions.map((position: any) => position.unrealizedPnl).reduce((a: number, b: number) => a + b, 0))
		.reduce((a: number, b: number) => a + b, 0)
		.toFixed(2);
	const total = (Number(balance) + Number(totalPnl)).toFixed(2);
	const bitget = accountBalance.filter((account: any) => account.exchange === "bitget");
	const bybit = accountBalance.filter((account: any) => account.exchange === "bybit");
	const bitgetBalances = {
		balance: bitget
			.map((account: any) => account.balance)
			.reduce((a: number, b: number) => a + b, 0)
			.toFixed(2),
		totalPnl: bitget
			.map((account: any) => account.positions.map((position: any) => position.unrealizedPnl).reduce((a: number, b: number) => a + b, 0))
			.reduce((a: number, b: number) => a + b, 0)
			.toFixed(2),
		total: (
			Number(
				bitget
					.map((account: any) => account.balance)
					.reduce((a: number, b: number) => a + b, 0)
					.toFixed(2)
			) +
			Number(
				bitget
					.map((account: any) => account.positions.map((position: any) => position.unrealizedPnl).reduce((a: number, b: number) => a + b, 0))
					.reduce((a: number, b: number) => a + b, 0)
					.toFixed(2)
			)
		).toFixed(2),
	};
	const bybitBalances = {
		balance: bybit
			.map((account: any) => account.balance)
			.reduce((a: number, b: number) => a + b, 0)
			.toFixed(2),
		totalPnl: bybit
			.map((account: any) => account.positions.map((position: any) => position.unrealizedPnl).reduce((a: number, b: number) => a + b, 0))
			.reduce((a: number, b: number) => a + b, 0)
			.toFixed(2),
		total: (
			Number(
				bybit
					.map((account: any) => account.balance)
					.reduce((a: number, b: number) => a + b, 0)
					.toFixed(2)
			) +
			Number(
				bybit
					.map((account: any) => account.positions.map((position: any) => position.unrealizedPnl).reduce((a: number, b: number) => a + b, 0))
					.reduce((a: number, b: number) => a + b, 0)
					.toFixed(2)
			)
		).toFixed(2),
	};

	return (
		<BlurWrapper onClick={() => setToggleBalance((cur: any) => !cur)}>
			<BalanceDetail layoutId="totalbalance" style={{}}>
				<motion.div>
					<motion.span layoutId="total" style={{ fontSize: "35px" }}>
						Total:{total}
					</motion.span>
					<motion.span style={{ fontSize: "25px", display: "flex", justifyContent: "space-between" }}>
						<motion.span layoutId="balance">Balance:{balance}</motion.span>
						<motion.span layoutId="pnl" style={Number(totalPnl) === 0 ? { color: "white" } : totalPnl > 0 ? { color: "green" } : { color: "red" }}>
							Pnl:{totalPnl}
						</motion.span>
					</motion.span>
				</motion.div>
				<motion.ul>
					<motion.li style={{}}>
						<motion.div style={{ display: "flex", alignItems: "center" }}>
							<motion.img src={exchangeLogo.bitget} style={{ aspectRatio: 1 / 1, width: "30px", padding: "5px" }} />
							<motion.span>bitget</motion.span>
						</motion.div>
						<motion.div style={{}}>
							<motion.div style={{ display: "flex", justifyContent: "space-around" }}>
								<motion.span style={{ fontSize: "20px" }}>total: {bitgetBalances.total}</motion.span>
								<motion.span style={{ fontSize: "20px" }}>Balance: {bitgetBalances.balance}</motion.span>
								<motion.span
									style={
										Number(bitgetBalances.totalPnl) === 0
											? { fontSize: "20px", color: "white" }
											: Number(bitgetBalances.totalPnl) > 0
											? { fontSize: "20px", color: "green" }
											: { fontSize: "20px", color: "red" }
									}>
									P&L: {bitgetBalances.totalPnl}
								</motion.span>
							</motion.div>
						</motion.div>
					</motion.li>
					<motion.li>
						<motion.div style={{ display: "flex", alignItems: "center" }}>
							<motion.img src={exchangeLogo.bybit} style={{ aspectRatio: 1 / 1, width: "30px", padding: "5px" }} />
							<motion.span>bybit</motion.span>
						</motion.div>
						<motion.div style={{ display: "flex", justifyContent: "space-around" }}>
							<motion.span style={{ fontSize: "20px" }}>total: {bybitBalances.total}</motion.span>
							<motion.span style={{ fontSize: "20px" }}>Balance: {bybitBalances.balance}</motion.span>
							<motion.span
								style={
									Number(bybitBalances.totalPnl) === 0
										? { fontSize: "20px", color: "white" }
										: Number(bybitBalances.totalPnl) > 0
										? { fontSize: "20px", color: "green" }
										: { fontSize: "20px", color: "red" }
								}>
								P&L: {bybitBalances.totalPnl}
							</motion.span>
						</motion.div>
					</motion.li>
				</motion.ul>
			</BalanceDetail>
		</BlurWrapper>
	);
};
export default ExchangeBalance;
