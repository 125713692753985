import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { candleData as CandleDataSet, tikerData } from "../../atoms/exchangeAtom";

const ChartDataComponent = (props: any) => {
	const { exchange, coin, timeFrame, setBalance } = props.data;
	const { setAllPositions, candleData, setChartLoading, setPositions, setPnl } = props;
	const [candle, setCandle] = useState<any>();
	const candleRecoil = useSetRecoilState(CandleDataSet);
	const setTicker = useSetRecoilState(tikerData);
	const [initialize, setInitialize] = useState(false);
	const add = (arr: []) => arr.reduce((a: number, b: number) => a + b, 0);

	useEffect(() => {
		setCandle(candleData);
		candleRecoil(candle);
		setChartLoading(true);
		(async () => {
			const { symbol } = coin;
			await exchange.fetchTicker(symbol).then((response: any) => setTicker(response));
			await exchange.fetchBalance().then((response: any) => {
				const balanceInfo = {
					free: response.free,
					used: response.used,
					total: response.total,
				};
				setBalance(balanceInfo);
			});
			await exchange
				.fetchOHLCV(coin.symbol, timeFrame)
				.then(async (originalArray: any) => {
					setCandle(originalArray);
					let timeScale = originalArray[1][0] - originalArray[0][0];
					let fullTime = originalArray[originalArray.length - 1][0] - originalArray[0][0];
					let pastTime = originalArray[0][0] - fullTime;
					let count = 70;
					while (count > 0 && isMounted) {
						const pastArray = await exchange.fetchOHLCV(coin.symbol, timeFrame, pastTime - timeScale).then((response: any) => {
							setCandle((cur: any) => {
								let times = cur.map((item: any) => item[0]);
								const filtered = response.filter((item: any) => times.includes(item[0]) === false);
								if (filtered < 1) {
									count = 0;
									return cur;
								}
								const copied = [...filtered, ...cur];
								return [...copied];
							});
							return response;
						});
						pastTime ? (pastTime = pastArray[0][0] - fullTime) : (count = 0);
						count -= 1;
					}
				})
				.then(() => {
					setInitialize(true);
				});
		})();

		let isMounted = true;
		const chartUpdate = async (candle: any) => {
			while (isMounted) {
				await exchange.fetchPositions().then((response: any) => {
					setPositions(response.filter((position: any) => position.symbol === coin.symbol));
					const positions = response.filter((position: any) => position.contracts > 0);
					setAllPositions(positions);
					setPnl(add(positions.map((item: any) => item.unrealizedPnl)));
				});
				await exchange.fetchBalance().then((response: any) => {
					const balanceInfo = {
						free: response.free,
						used: response.used,
						total: response.total,
					};
					setBalance(balanceInfo);
				});
				const candles = await exchange.watch_ohlcv(coin.symbol, timeFrame, 1000).then((response: any) =>
					setCandle((cur: any) => {
						const copid = [...cur];
						const timeScale = copid[1][0] - copid[0][0];
						const lastTime = copid[copid.length - 1][0];
						const nextCandle = lastTime + timeScale;
						if (response[response.length - 1][0] <= nextCandle) {
							const updateCandle = [
								lastTime,
								response[response.length - 1][1],
								response[response.length - 1][2],
								response[response.length - 1][3],
								response[response.length - 1][4],
								response[response.length - 1][5],
							];
							copid.pop();
							const final = [...copid, updateCandle];
							const finalArray = Array.from(new Set(final));
							return finalArray;
						} else {
							const updateCandle = [
								nextCandle,
								response[response.length - 1][1],
								response[response.length - 1][2],
								response[response.length - 1][3],
								response[response.length - 1][4],
								response[response.length - 1][5],
							];
							const final = [...copid, updateCandle];
							const finalArray = Array.from(new Set(final));
							return finalArray;
						}
					})
				);
			}
		};
		chartUpdate(candle);
		return () => {
			isMounted = false;
			setChartLoading(true);
			setTicker(null);
		};
	}, [timeFrame]);

	useEffect(() => {
		candleRecoil(candle);
		if (initialize) {
			setChartLoading(false);
		}
	}, [candle]);

	return <></>;
};
export default ChartDataComponent;
