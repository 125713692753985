import { motion } from "framer-motion";
import styled from "styled-components";

export const AddAccountWrapper = styled(motion.div)`
	padding: 20% 10%;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	-webkit-backdrop-filter: blur(15px);
	overflow: hidden;
`;
export const AddAccountContainer = styled(motion.div)`
	width: 100%;
	display: flex;
	justify-content: center;
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 30px;
	box-shadow: 9px 16px 16px hsl(0deg 0% 0% / 0.25);
	margin-bottom: 5%;
`;

export const AddAccountForm = styled(motion.form)`
	width: 100%;
	height: 100%;
	padding: 5%;
	display: flex;
	flex-direction: column;

	input {
		padding: 0 3%;
		padding-bottom: 3%;
		width: 100%;
		height: 100%;
		background: none;
		color: white;
		border: none;
		font-size: 20px;
		border-bottom: 2px solid rgba(255, 255, 255, 0.1);
	}
	button {
		background: none;
		outline: none;
		border: none;
		color: white;
		font-size: larger;
		display: flex;
		justify-content: center;
		padding: 5%;
		span {
			margin-top: 3%;
			border: 1px solid white;
			padding: 1% 5%;
			border-radius: 25px;
		}
	}
`;
export const AddAccountErrors = styled(motion.span)`
	font-size: 20px;
	color: orange;
	padding-left: 3%;
`;
