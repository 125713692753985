import { motion } from "framer-motion";
import styled from "styled-components";

export const CoinsWrapper = styled(motion.div)`
	padding: 15% 5%;
	height: 100svh;
	width: 100svw;
`;

export const CoinsContainer = styled(motion.div)`
	width: 100%;
	height: 100%;
	overflow-x: hidden;
`;

export const CoinsCoinsList = styled(motion.ul)`
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	margin-top: 170px;
	margin-bottom: 40px;
`;
export const CoinsCoin = styled(motion.li)`
	display: flex;
	align-items: center;
	justify-content: center;
	aspect-ratio: 1/1;
	background: rgba(255, 255, 255, 0.1);
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	box-shadow: 9px 16px 16px hsl(0deg 0% 0% / 0.25);
	margin: 10px;
	flex-direction: column;
	border-radius: 30px;

	a {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	img {
		width: 65px;
		aspect-ratio: 1/1;
		border-radius: 50%;
		background-color: #ccdaf4;
		box-shadow: 9px 16px 16px hsl(0deg 0% 0% / 0.6);
		border: 1px solid rgba(255, 255, 255, 1);
	}

	span {
		font-size: 15px;
		color: white;
	}
`;

export const CoinsSearchForm = styled(motion.form)`
	position: fixed;
	top: 100px;
	width: 90%;
	align-self: center;

	background: rgba(255, 255, 255, 0.05);
	-webkit-backdrop-filter: blur(30px);
	backdrop-filter: blur(30px);
	border-radius: 30px;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	box-shadow: 9px 16px 16px hsl(0deg 0% 0% / 0.25);
	input {
		width: 100%;
		height: 45px;
		border-radius: 30px;
		background: none;
		border: none;
		padding: 5%;
		font-size: 20px;
		color: white;
		outline: none;
		box-shadow: 9px 16px 16px hsl(0deg 0% 0% / 0.25);
	}
`;
export const CoinsLiner = styled(motion.div)`
	width: 100%;
	height: 100%;
	background-color: none;
	box-shadow: 9px 16px 16px hsl(0deg 0% 0% / 0.25);
`;
export const CoinsUserInfo = styled(motion.span)`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: end;
	font-size: 30px;
	color: white;
`;
export const CoinsInfo = styled(motion.div)`
	width: 300%;
	display: flex;
	font-size: 40px;
	color: white;
	padding-left: 40px;
	padding-top: 50px;
`;
export const CoinsLogoImg = styled.img``;
export const CoinsCoinName = styled.span``;
