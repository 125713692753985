import { atom, selector } from "recoil";

type exchange = "bybit" | "binance" | "bitget";

export interface IExAccount {
	name: string;
	exchange: exchange;
	ccxtExchange: any;
	apiKey: string;
	secret: string;
	password?: string | null | undefined;
	uid?: string | null | undefined;
}
export interface IFilteredExAccount {
	name: string;
	exchange: exchange;
	ccxtExchange: any;
}

export const userAccountState = atom<IFilteredExAccount[]>({
	key: "userAcocunts",
	default: [],
	// TypeError: Cannot freeze 방지
	dangerouslyAllowMutability: true,
});

export const filteredUserAccountState = selector({
	key: "filteredUserAccount",
	get: ({ get }) => {
		const exchanges = ["bybit", "binance", "bitget"];
		const accounts = get(userAccountState);
		const bybit = accounts.filter((account) => account.exchange === "bybit").length > 0 ? accounts.filter((account) => account.exchange === "bybit") : null;
		const binance =
			accounts.filter((account) => account.exchange === "binance").length > 0 ? accounts.filter((account) => account.exchange === "binance") : null;
		const bitget = accounts.filter((account) => account.exchange === "bitget").length > 0 ? accounts.filter((account) => account.exchange === "bitget") : null;
		return [
			{ exchangeId: "bybit", data: bybit },
			// { exchangeId: "binance", data: binance },
			{ exchangeId: "bitget", data: bitget },
		];
	},
	dangerouslyAllowMutability: true,
});

export const accountForTradeState = atom<IExAccount>({
	key: "currentAccount",
	dangerouslyAllowMutability: true,
});
