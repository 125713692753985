import { motion } from "framer-motion";
import styled from "styled-components";

export const ExchangeLists = styled(motion.ul)`
	overflow-y: scroll;
	padding-top: 65px;
	padding-bottom: 80px;
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
`;
export const ExchangeIcon = styled(motion.img)`
	aspect-ratio: 1/1;
	width: 70px;
	padding: 8%;
	background-color: black;
	border-radius: 25px;
	box-shadow: 9px 16px 16px hsl(0deg 0% 0% / 0.25);
`;
export const exchangeLogo: any = {
	binance: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Binance_Logo.svg/120px-Binance_Logo.svg.png",
	bybit: "https://banxa.com/wp-content/uploads/2022/02/bybit-1-1.svg",
	bitget: "https://stakingcrypto.info/static/assets/coins/bitget-token-new-logo.png",
};

export const ExchangeList = styled(motion.li)`
	display: flex;
	width: 90%;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
	border-radius: 30px;
	margin: 10px;
	background-color: rgba(255, 255, 255, 0.05);
	/* border: 1px solid rgba(255, 255, 255, 0.1); */
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	box-shadow: 9px 16px 16px hsl(0deg 0% 0% / 0.25);
	height: 100px;
`;
export const ExchangeListIcon = styled(motion.div)`
	display: flex;
	align-items: center;
`;

export const ExchangeListRight = styled<any>(motion.div)``;
export const ExchangeTitle = styled(motion.div)`
	margin-left: 15%;
	font-size: 35px;
	font-weight: 500;
	color: white;
`;
export const ExchangeAccountLists = styled(motion.ul)``;
