import Header from "../Components/Header";
import { useRecoilValue } from "recoil";
import { useEffect } from "react";
import { filteredUserAccountState, userAccountState } from "../atoms/userAccounts";
import { Container, RootBackgroundColor, Wrapper } from "../styleComponents/generalStyles";
import { Outlet, useParams } from "react-router-dom";
import { initializeAccount } from "../atoms/authAtom";
import ExchangeToggle from "../Components/AccountComponent/ExchangeToggle";
import { ExchangeLists } from "../styleComponents/exchangeStyle";
import Navigator from "../Components/Navigator";

const Exchanges = () => {
	const exchangesAccounts = useRecoilValue(filteredUserAccountState);
	const userAccounts = useRecoilValue(userAccountState);
	const reFetchAccount: any = useRecoilValue(initializeAccount);
	const { exchangeId } = useParams();
	useEffect(() => {
		reFetchAccount.refetch();
	}, []);

	return (
		<Wrapper wrapperColor={RootBackgroundColor.chart} transition={{ type: "keyframes" }}>
			<Outlet />
			<Header pageTitle={exchangeId ? exchangeId : "Exchanges"} />
			{userAccounts ? (
				<Container style={{}}>
					{exchangeId ? (
						<ExchangeLists transition={{ type: "keyframes" }}>
							{exchangesAccounts?.map((exchange: any) => (
								<ExchangeToggle key={exchange.exchangeId} exchangeId={exchange.exchangeId} data={exchange.data} reFetchAccount={reFetchAccount} />
							))}
						</ExchangeLists>
					) : (
						<ExchangeLists initial={{ y: 100, scale: 0, opacity: 0 }} animate={{ y: 0, scale: 1, opacity: 1 }}>
							{exchangesAccounts?.map((exchange: any) => (
								<ExchangeToggle key={exchange.exchangeId} exchangeId={exchange.exchangeId} data={exchange.data} reFetchAccount={reFetchAccount} />
							))}
						</ExchangeLists>
					)}
				</Container>
			) : (
				<span></span>
			)}
			{exchangeId ? null : <Navigator />}
		</Wrapper>
	);
};

export default Exchanges;
