import { motion } from "framer-motion";
import { ChartScreenTimeDiv, TimeFrameSelectorContainer } from "../../styleComponents/chartScreenStyle";
import { TimeFrameSelection } from "../../styleComponents/chartScreenStyle";
import { useEffect, useRef, useState } from "react";

export const spring = {
	type: "spring",
	stiffness: 700,
	damping: 30,
	duration: 0.1,
};
const TimeFrameSelector = (props: any) => {
	const [extraTimeFrame, setExtraTF] = useState<"1m" | "3m" | "1d" | "1w" | "1M" | null>();
	const {
		timeFrameState: { setTimeFrame, timeFrame },
		moreTfState: { moreTF, setMoreTF },
	} = props;

	const componentRef = useRef<any>(null);
	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (componentRef.current && !componentRef.current.contains(event.target)) {
			}
		};
		document.addEventListener("touchstart", handleClickOutside);
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("touchstart", handleClickOutside);
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [componentRef]);

	useEffect(() => {
		const extraTime = ["1m", "3m", "1d", "1w", "1M"];
		extraTime.includes(timeFrame) ? setExtraTF(timeFrame) : setExtraTF(null);
	}, [timeFrame]);

	return (
		<TimeFrameSelectorContainer>
			<ChartScreenTimeDiv
				layoutId="timeFrameSelector"
				style={{ boxSizing: "border-box" }}
				initial={{ opacity: 0, y: -100 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: -100 }}>
				{timeFrame !== "5m" ? (
					<TimeFrameSelection
						whileTap={{ scale: 0.95 }}
						ref={componentRef}
						onClick={() => {
							setTimeFrame("5m");
							setMoreTF(false);
						}}
						initial={{ opacity: 0.5, scale: 0, y: 5 }}
						animate={{ opacity: 1, scale: 1, y: 0, transition: { type: "spring" } }}
						exit={{ opacity: 0.5, scale: 0, y: 5 }}
						transition={spring}>
						5m
					</TimeFrameSelection>
				) : (
					<TimeFrameSelection
						key="Clicked"
						layoutId="selectedTimeFrame"
						style={{ backgroundColor: "orange", color: "black", boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)" }}
						transition={{ duration: 0.2 }}>
						5m
					</TimeFrameSelection>
				)}
				{timeFrame !== "15m" ? (
					<TimeFrameSelection
						whileTap={{ scale: 0.95 }}
						onClick={() => {
							setTimeFrame("15m");
							setMoreTF(false);
						}}
						initial={{ opacity: 0.5, scale: 0, y: 5 }}
						animate={{ opacity: 1, scale: 1, y: 0, transition: { type: "spring" } }}
						exit={{ opacity: 0.5, scale: 0, y: 5 }}
						transition={spring}>
						15m
					</TimeFrameSelection>
				) : (
					<TimeFrameSelection
						key="Clicked"
						layoutId="selectedTimeFrame"
						style={{ backgroundColor: "orange", color: "black", boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)" }}
						transition={{ duration: 0.2 }}>
						15m
					</TimeFrameSelection>
				)}
				{timeFrame !== "30m" ? (
					<TimeFrameSelection
						whileTap={{ scale: 0.95 }}
						onClick={() => {
							setTimeFrame("30m");
							setMoreTF(false);
						}}
						initial={{ opacity: 0.5, scale: 0, y: 5 }}
						animate={{ opacity: 1, scale: 1, y: 0, transition: { type: "spring" } }}
						exit={{ opacity: 0.5, scale: 0, y: 5 }}
						transition={spring}>
						30m
					</TimeFrameSelection>
				) : (
					<TimeFrameSelection
						key="Clicked"
						layoutId="selectedTimeFrame"
						style={{ backgroundColor: "orange", color: "black", boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)" }}
						transition={{ duration: 0.2 }}>
						30m
					</TimeFrameSelection>
				)}
				{timeFrame !== "1h" ? (
					<TimeFrameSelection
						whileTap={{ scale: 0.95 }}
						onClick={() => {
							setTimeFrame("1h");
							setMoreTF(false);
						}}
						initial={{ opacity: 0.5, scale: 0, y: 5 }}
						animate={{ opacity: 1, scale: 1, y: 0, transition: { type: "spring" } }}
						exit={{ opacity: 0.5, scale: 0, y: 5 }}
						transition={spring}>
						1h
					</TimeFrameSelection>
				) : (
					<TimeFrameSelection
						key="Clicked"
						layoutId="selectedTimeFrame"
						style={{ backgroundColor: "orange", color: "black", boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)" }}
						transition={{ duration: 0.2 }}>
						1h
					</TimeFrameSelection>
				)}
				{timeFrame !== "4h" ? (
					<TimeFrameSelection
						whileTap={{ scale: 0.95 }}
						onClick={() => {
							setTimeFrame("4h");
							setMoreTF(false);
						}}
						initial={{ opacity: 0.5, scale: 0, y: 5 }}
						animate={{ opacity: 1, scale: 1, y: 0, transition: { type: "spring" } }}
						exit={{ opacity: 0.5, scale: 0, y: 5 }}
						transition={spring}>
						4h
					</TimeFrameSelection>
				) : (
					<TimeFrameSelection
						key="Clicked"
						layoutId="selectedTimeFrame"
						style={{ backgroundColor: "orange", color: "black", boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)" }}
						transition={{ duration: 0.2 }}>
						4h
					</TimeFrameSelection>
				)}

				{!moreTF ? (
					extraTimeFrame ? (
						<TimeFrameSelection
							whileTap={{ scale: 0.95 }}
							ref={componentRef}
							style={{ backgroundColor: "orange", color: "black", boxSizing: "border-box", boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)" }}
							layoutId="selectedTimeFrame"
							onClick={() => setMoreTF((cur: any) => !cur)}
							animate={{ rotate: 0 }}
							exit={{ rotate: 180 }}>
							<span style={{ marginLeft: "5px", marginRight: "-5px" }}>{extraTimeFrame === "1d" ? "1D" : extraTimeFrame === "1w" ? "1W" : extraTimeFrame}</span>
							<i style={{ fontSize: "10px" }} className="fa-solid fa-caret-down"></i>
						</TimeFrameSelection>
					) : (
						<TimeFrameSelection
							whileTap={{ scale: 0.95 }}
							ref={componentRef}
							key="moreTF"
							layoutId="moreTF"
							onClick={() => setMoreTF((cur: boolean) => !cur)}
							animate={{ rotate: 0 }}
							exit={{ rotate: 180 }}>
							<i className="fa-solid fa-caret-down"></i>
						</TimeFrameSelection>
					)
				) : (
					<TimeFrameSelection
						whileTap={{ scale: 0.95 }}
						key="moreTF"
						layoutId="moreTF"
						animate={{ rotate: 180 }}
						exit={{ rotate: 0 }}
						onClick={() => setMoreTF(false)}
						style={{ backgroundColor: "orange", color: "black", boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)" }}>
						<i className="fa-solid fa-caret-down"></i>
					</TimeFrameSelection>
				)}
			</ChartScreenTimeDiv>
			{moreTF ? (
				<ChartScreenTimeDiv>
					{timeFrame !== "1m" ? (
						<TimeFrameSelection
							whileTap={{ scale: 0.95 }}
							onClick={() => {
								setTimeFrame("1m");
								setMoreTF((cur: boolean) => !cur);
							}}
							initial={{ opacity: 0.5, scale: 0, y: 5 }}
							animate={{ opacity: 1, scale: 1, y: 0, transition: { type: "spring" } }}
							exit={{ opacity: 0.5, scale: 0, y: 5 }}
							transition={spring}>
							1m
						</TimeFrameSelection>
					) : (
						<TimeFrameSelection
							key="Clicked"
							layoutId="selectedTimeFrame"
							style={{ backgroundColor: "orange", color: "black", boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)" }}
							transition={{ duration: 0.2 }}>
							1m
						</TimeFrameSelection>
					)}
					{timeFrame !== "3m" ? (
						<TimeFrameSelection
							whileTap={{ scale: 0.95 }}
							onClick={() => {
								setTimeFrame("3m");
								setMoreTF((cur: boolean) => !cur);
							}}
							initial={{ opacity: 0.5, scale: 0, y: 5 }}
							animate={{ opacity: 1, scale: 1, y: 0, transition: { type: "spring" } }}
							exit={{ opacity: 0.5, scale: 0, y: 5 }}
							transition={spring}>
							3m
						</TimeFrameSelection>
					) : (
						<TimeFrameSelection
							key="Clicked"
							layoutId="selectedTimeFrame"
							style={{ backgroundColor: "orange", color: "black", boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)" }}
							transition={{ duration: 0.2 }}>
							3m
						</TimeFrameSelection>
					)}
					{timeFrame !== "1d" ? (
						<TimeFrameSelection
							whileTap={{ scale: 0.95 }}
							onClick={() => {
								setTimeFrame("1d");
								setMoreTF((cur: boolean) => !cur);
							}}
							initial={{ opacity: 0.5, scale: 0, y: 5 }}
							animate={{ opacity: 1, scale: 1, y: 0, transition: { type: "spring" } }}
							exit={{ opacity: 0.5, scale: 0, y: 5 }}
							transition={spring}>
							1D
						</TimeFrameSelection>
					) : (
						<TimeFrameSelection
							key="Clicked"
							layoutId="selectedTimeFrame"
							style={{ backgroundColor: "orange", color: "black", boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)" }}
							transition={{ duration: 0.2 }}>
							1D
						</TimeFrameSelection>
					)}
					{timeFrame !== "1w" ? (
						<TimeFrameSelection
							whileTap={{ scale: 0.95 }}
							onClick={() => {
								setTimeFrame("1w");
								setMoreTF((cur: boolean) => !cur);
							}}
							initial={{ opacity: 0.5, scale: 0, y: 5 }}
							animate={{ opacity: 1, scale: 1, y: 0, transition: { type: "spring" } }}
							exit={{ opacity: 0.5, scale: 0, y: 5 }}
							transition={spring}>
							1W
						</TimeFrameSelection>
					) : (
						<TimeFrameSelection
							key="Clicked"
							layoutId="selectedTimeFrame"
							style={{ backgroundColor: "orange", color: "black", boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)" }}
							transition={{ duration: 0.2 }}>
							1W
						</TimeFrameSelection>
					)}
					{timeFrame !== "1M" ? (
						<TimeFrameSelection
							whileTap={{ scale: 0.95 }}
							onClick={() => {
								setTimeFrame("1M");
								setMoreTF((cur: boolean) => !cur);
							}}
							initial={{ opacity: 0.5, scale: 0, y: 5 }}
							animate={{ opacity: 1, scale: 1, y: 0, transition: { type: "spring" } }}
							exit={{ opacity: 0.5, scale: 0, y: 5 }}
							transition={spring}>
							1M
						</TimeFrameSelection>
					) : (
						<TimeFrameSelection
							key="Clicked"
							layoutId="selectedTimeFrame"
							style={{ backgroundColor: "orange", color: "black", boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)" }}
							transition={{ duration: 0.2 }}>
							1M
						</TimeFrameSelection>
					)}
				</ChartScreenTimeDiv>
			) : null}
		</TimeFrameSelectorContainer>
	);
};
export default TimeFrameSelector;
