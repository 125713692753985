import { motion } from "framer-motion";
import Header from "../Components/Header";
import { RootBackgroundColor, Wrapper } from "../styleComponents/generalStyles";
import Navigator from "../Components/Navigator";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { userAccountState } from "../atoms/userAccounts";
import ExchangeBalance from "../Components/ExchangeBalance";
import WaveLoading from "../Components/WaveLoading";
import Positions from "../Components/Positions";
import { exchangeLogo } from "../styleComponents/exchangeStyle";
import { useQuery } from "react-query";

const HomeCointainer = styled(motion.div)`
	padding-top: 65px;
	margin-bottom: 120px;
	height: 100%;
	width: 100%;
`;
const HomeTopInfo = styled(motion.div)`
	display: flex;
	padding: 10px;
`;

const HomeInfoCard = styled(motion.div)`
	width: 50%;
	height: auto;
	aspect-ratio: 1/1;
	margin: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 20px;
	line-height: 0.6;
	text-align: center;
	color: white;
	box-sizing: border-box;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 20px;
	background-color: rgba(255, 255, 255, 0.1);
	box-shadow: 9px 16px 16px hsl(0deg 0% 0% / 0.25);
	span {
		display: flex;
	}
`;

const Home = () => {
	const accounts = useRecoilValue(userAccountState);
	const [toggleBalance, setToggleBalance] = useState(false);
	const [togglePositions, setTogglePositions] = useState(false);
	const [accountBalance, setAccountBalance] = useState<any>([]);
	const [initialize, setInitialize] = useState(false);
	const [balanceInfo, setBalanceInfo] = useState<any>();
	const [refetch, setRefetch] = useState(false);

	useEffect(() => {
		(async () => {
			setInitialize(false);
			setAccountBalance([]);
			await accounts.map(async (account) => {
				await account.ccxtExchange.fetchBalance().then(async (response: any) => {
					const positions = await account.ccxtExchange.fetchPositions().then((response: any) => response.filter((contract: any) => contract.contracts > 0));
					setAccountBalance((cur: any) => [
						...cur,
						{ name: account.name, exchange: account.exchange, balance: response.total.USDT, positions: positions, ccxtExchange: account.ccxtExchange },
					]);
				});
				if (account.name === accounts[accounts.length - 1].name) setInitialize(true);
			});
		})();
		return () => {};
	}, []);

	useEffect(() => {
		if (initialize) {
			const balance = accountBalance
				.map((account: any) => account.balance)
				.reduce((a: number, b: number) => a + b, 0)
				.toFixed(2);
			const totalPnl = accountBalance
				.map((account: any) => account.positions.map((position: any) => position.unrealizedPnl).reduce((a: number, b: number) => a + b, 0))
				.reduce((a: number, b: number) => a + b, 0)
				.toFixed(2);
			const total = (Number(balance) + Number(totalPnl)).toFixed(2);
			setBalanceInfo({ balance, total, totalPnl });
		}
	}, [accountBalance]);

	return (
		<Wrapper wrapperColor={RootBackgroundColor.chart} transition={{ type: "keyframes" }}>
			<Header pageTitle={"Home"} />
			{initialize && balanceInfo ? (
				<>
					<HomeCointainer>
						<HomeTopInfo>
							<HomeInfoCard whileTap={{ scale: 0.95 }} onClick={() => setToggleBalance((cur) => !cur)} layoutId="totalbalance">
								<motion.span layoutId="total" style={{ fontSize: "30px" }}>
									Total: {balanceInfo.total}
								</motion.span>
								<motion.span layoutId="balance" style={{ fontSize: "20px" }}>
									Balance: {balanceInfo.balance}
								</motion.span>
								{Number(balanceInfo.totalPnl) === 0 ? (
									<motion.span style={{ fontSize: "20px", color: "orange" }}>no trade</motion.span>
								) : (
									<motion.span
										layoutId="pnl"
										style={
											Number(balanceInfo.totalPnl) === 0
												? { fontSize: "20px", color: "white" }
												: balanceInfo.totalPnl > 0
												? { fontSize: "20px", color: "green" }
												: { fontSize: "20px", color: "red" }
										}>
										Pnl: {balanceInfo.totalPnl}
									</motion.span>
								)}
								<motion.span style={{ alignSelf: "end", color: "teal" }}>&rarr; Click for detail</motion.span>
							</HomeInfoCard>
							<HomeInfoCard onClick={() => setTogglePositions((cur) => !cur)} whileTap={{ scale: 0.95 }} layoutId="positions">
								<motion.span style={{ fontSize: "30px" }}>
									Positions: {accountBalance.map((account: any) => account.positions.length).reduce((a: number, b: number) => a + b, 0)}
								</motion.span>
								{accountBalance.map((account: any) => account.positions.length).reduce((a: number, b: number) => a + b, 0) === 0 ? (
									<motion.div style={{ fontSize: "25px", color: "orange" }}>No Positions</motion.div>
								) : (
									<motion.ul>
										<motion.li>
											<motion.div style={{ display: "flex", alignItems: "center" }}>
												<motion.img src={exchangeLogo.bitget} style={{ aspectRatio: 1 / 1, width: "30px", padding: "5px" }} />
												<motion.span>
													bitget:
													{accountBalance
														.filter((account: any) => account.exchange === "bitget")
														.map((account: any) => account.positions.length)
														.reduce((a: number, b: number) => a + b, 0)}
												</motion.span>
											</motion.div>
										</motion.li>
										<motion.li>
											<motion.div style={{ display: "flex", alignItems: "center" }}>
												<motion.img src={exchangeLogo.bybit} style={{ aspectRatio: 1 / 1, width: "30px", padding: "5px" }} />
												<motion.span>
													bybit:
													{accountBalance
														.filter((account: any) => account.exchange === "bybit")
														.map((account: any) => account.positions.length)
														.reduce((a: number, b: number) => a + b, 0)}
												</motion.span>
											</motion.div>
										</motion.li>
									</motion.ul>
								)}
								<motion.span style={{ alignSelf: "end", color: "teal" }}>&rarr; Click for detail</motion.span>
							</HomeInfoCard>
						</HomeTopInfo>
					</HomeCointainer>
				</>
			) : (
				<motion.div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
					<WaveLoading />
				</motion.div>
			)}
			{toggleBalance ? <ExchangeBalance accountBalance={accountBalance} setToggleBalance={setToggleBalance} /> : null}
			{togglePositions ? <Positions accountBalance={accountBalance} setTogglePositions={setTogglePositions} /> : null}
			<Navigator />
		</Wrapper>
	);
};

export default Home;
