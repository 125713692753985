import { motion } from "framer-motion";
import styled from "styled-components";

const Dots = styled(motion.span)`
	color: white;
	font-weight: 700;
`;

const blinking = {
	initial: {
		opacity: 0.5,
	},
	animate: {
		opacity: [0.5, 1, 0.5],
		transition: { repeat: Infinity, staggerChildren: 0.2, duration: 1 },
	},
};

export const TextLoading = (props: any) => {
	const { margin, fontSize, display, flexDirection } = props;
	return (
		<motion.span style={{ fontSize, display, flexDirection }} variants={blinking} initial="initial" animate="animate">
			<Dots style={{ margin }} variants={blinking}>
				.
			</Dots>
			<Dots style={{ margin }} variants={blinking}>
				.
			</Dots>
			<Dots style={{ margin }} variants={blinking}>
				.
			</Dots>
			<Dots style={{ margin }} variants={blinking}>
				.
			</Dots>
			<Dots style={{ margin }} variants={blinking}>
				.
			</Dots>
		</motion.span>
	);
};

export default TextLoading;
