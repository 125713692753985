import { motion } from "framer-motion";
import { marketClose } from "../../api/coinApi";
import { ChartScreenBottomContainer } from "../../styleComponents/chartScreenStyle";
import { LoadingText } from "../../styleComponents/loadingStyle";
import { PositionLists } from "../../styleComponents/positionStyle";
import TextLoading from "../TextLoading";
import WaveLoading from "../WaveLoading";
import PositionInfoCard from "./PositionInfoCard";

const PositionComponent = (props: any) => {
	const { positions, balance, candleUpdate, exchange, setTradeConfirm } = props.data;

	return (
		<ChartScreenBottomContainer
			key="positions"
			initial={{ opacity: 0.5, scale: 0, x: 300 }}
			animate={{ opacity: 1, scale: 1, x: 0 }}
			exit={{ opacity: 0.5, scale: 0, x: 300 }}>
			{positions && balance && !candleUpdate.isFetching ? (
				positions.length > 0 ? (
					<PositionLists>
						{positions?.map((position: any) => {
							return position.contracts > 0 ? <PositionInfoCard data={{ position, marketClose, setTradeConfirm, exchange }} /> : null;
						})}
						{!positions[0].contracts && !positions[1].contracts ? <motion.div>No Positions</motion.div> : null}
					</PositionLists>
				) : (
					<PositionLists>
						<motion.div>No Positions</motion.div>
					</PositionLists>
				)
			) : (
				<PositionLists>
					<TextLoading fontSize="60px" margin="0 3px" />
				</PositionLists>
			)}
		</ChartScreenBottomContainer>
	);
};

export default PositionComponent;
