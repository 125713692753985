import { AnimatePresence, color, motion, useCycle } from "framer-motion";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { authService } from "../firebase";
import { HeaderNav, HeaderPageTitle, HeaderWrapper } from "../styleComponents/headerStyle";
import HeaderToggleMenu from "./HeaderToggle";

function Header(props: any) {
	const { pageTitle, percentage, background, boxShadow, backdropFilter, WebkitBackdropFilter, loading } = props;
	const { exchangeId, coinId } = useParams();
	const navigate = useNavigate();

	return (
		<HeaderWrapper style={{ background: background, boxShadow, backdropFilter, WebkitBackdropFilter }}>
			<HeaderPageTitle
				initial={{ opacity: 0.5, scale: 0, y: 5 }}
				animate={{ opacity: 1, scale: 1, y: 0, transition: { type: "keyframes" } }}
				exit={{ opacity: 0.5, scale: 0, y: 5 }}>
				{exchangeId ? <span onClick={() => navigate(-1)}>&larr;</span> : null}
				{pageTitle}
				{percentage ? (
					<span
						style={{
							textAlign: "center",
							borderRadius: "5px",
							fontSize: "18px",
							marginLeft: "10px",
							color: `${percentage < 0 ? "rgba(232,58,19,1)" : "rgba(44,170,39,1)"}`,
						}}>
						{percentage > 0 ? `+${percentage.toFixed(2)}%` : `${percentage.toFixed(2)}%`}
					</span>
				) : null}
			</HeaderPageTitle>
			<HeaderToggleMenu />
		</HeaderWrapper>
	);
}

export default Header;
