import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import authState, { isLoggedInState } from "../atoms/authAtom";
import { authService } from "../firebase";

const UserAuth = () => {
	const [user, setUser] = useRecoilState(authState);
	const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const unsubscribe = authService.onAuthStateChanged(async (authUser) => {
			if (authUser) {
				setUser(authUser);
				setIsLoggedIn(true);
				setIsLoading(false);
			} else if (!authUser) {
				setIsLoggedIn(false);
			}
		});
		return () => {
			unsubscribe();
		};
	}, [setUser, setIsLoggedIn]);

	return { user, isLoggedIn, isLoading };
};
export default UserAuth;
