import { ccxt } from "..";
import { IExAccount } from "../atoms/userAccounts";

export const setupExchange = (accountInfo: IExAccount) => {
	const { secret, apiKey, password, uid } = accountInfo;
	const exchange = new ccxt[accountInfo.exchange]({
		proxy: "https://rtw-proxy-server.herokuapp.com/",
		enableRateLimit: true,
		apiKey,
		secret,
		password: password ? password : null,
		uid: uid ? uid : null,
		options: {
			defaultType: "swap",
			tradesLimit: 1000,
			OHLCVLimit: 1000,
			ordersLimit: 1000,
		},
	});
	return exchange;
};

export const accountBalance = async (exchange: any) => {
	const balance = await exchange.fetchBalance();
	const balanceInfo = {
		free: balance.free,
		used: balance.used,
		total: balance.total,
	};
	return balanceInfo;
};

export const getAllCoins = async (exchange: any) => {
	const allCoins = await exchange.loadMarkets();
	const newArray = Object.values(allCoins).map((value: any) => value) as any;
	const filteredArray = newArray.filter((coin: any) => coin.swap === true).filter((coin: any) => coin.settle === "USDT");

	return filteredArray;
};

export const stopLossPaint = (candles: any) => {
	const lowCandle = searchingStopLossCandle(candles, candles.length - 1, "low");
	const low = {
		time: lowCandle.time,
		position: "belowBar",
		color: "#2196F3",
		shape: "arrowUp",
		text: `StopLoss Low ${lowCandle.low}`,
		value: lowCandle.low,
	} as any;
	const highCandle = searchingStopLossCandle(candles, candles.length - 1, "high");
	const high = {
		time: highCandle.time,
		position: "aboveBar",
		color: "#e91e63",
		shape: "arrowDown",
		text: `StopLoss High ${highCandle.high}`,
		value: highCandle.high,
	} as any;
	return { low, high };
};

export const marketClose = async (exchange: any, positionIdx: number, symbol: string, amount: any, side: string) => {
	if (exchange.name === "Bybit") {
		if (Number(positionIdx) === 1) {
			try {
				const stopLossOrder = await exchange.createMarketSellOrder(symbol, amount, { positionIdx });
				return { complete: { marketClose: `${amount} of Long Position Closed` } };
			} catch (e) {
				return { error: e, from: "MarketClose" };
			}
		} else if (Number(positionIdx) === 2) {
			try {
				const stopLossOrder = await exchange.createMarketBuyOrder(symbol, amount, { positionIdx });
				return { complete: { marketClose: `${amount} of Short Position Closed` } };
			} catch (e) {
				return { error: e, from: "MarketClose" };
			}
		}
	} else if (exchange.name === "Bitget") {
		if (side === "short") {
			try {
				const stopLossOrder = await exchange.createMarketSellOrder(symbol, amount, { side: "close_short", reduceOnly: true });
				return { complete: { marketClose: `${amount} of Short Position Closed` } };
			} catch (e) {
				return { error: e, from: "MarketClose" };
			}
		} else {
			try {
				const stopLossOrder = await exchange.createMarketSellOrder(symbol, amount, { side: "close_long", reduceOnly: true });
				return { complete: { marketClose: `${amount} of Long Position Closed` } };
			} catch (e) {
				return { error: e, from: "MarketClose" };
			}
		}
	}
};

export const trade = async (exchange: any, coin: any, type: "low" | "high", tradeObject: any) => {
	const { symbol } = coin;
	const positionMode = { "one-way": 0, low: 1, high: 2 };
	const { amount, partialClose, stopLossPrice, maxLeverage } = tradeObject;
	const mod = positionMode[type];

	exchange.setPositionMode(mod, symbol);
	exchange.setLeverage(maxLeverage, symbol);

	if (exchange.name === "Bybit") {
		if (type === "low") {
			try {
				const order = await exchange.createMarketBuyOrder(symbol, amount, { positionIdx: mod, leverage: maxLeverage });
				try {
					const stopLossOrder = await exchange.createMarketSellOrder(symbol, amount, { positionIdx: mod, stopPrice: stopLossPrice, reduceOnly: true });
					try {
						const partialCloseOrder = await exchange.createLimitSellOrder(symbol, amount / 2, partialClose, { positionIdx: mod, reduceOnly: true });
						return { complete: { order: `MarketBuy:${amount}`, stopLoss: `StopLoss at: ${stopLossPrice}`, partialClose: `Half close at: ${partialClose}` } };
					} catch (e) {
						return { error: e, from: "partialCloseOrder", complete: { order: `MarketBuy:${amount}`, stopLoss: `StopLoss at: ${stopLossPrice}` } };
					}
				} catch (e) {
					return { error: e, from: "stopLossOrder", complete: { order: `MarketBuy:${amount}` } };
				}
			} catch (e) {
				return { error: e, from: "order" };
			}
		} else if (type === "high") {
			try {
				const order = await exchange.createMarketSellOrder(symbol, amount, { positionIdx: mod, leverage: maxLeverage });
				try {
					const stopLossOrder = await exchange.createMarketBuyOrder(symbol, amount, {
						positionIdx: mod,
						stopPrice: stopLossPrice,
						reduceOnly: true,
					});
					try {
						const partialCloseOrder = await exchange.createLimitBuyOrder(symbol, amount / 2, partialClose, { positionIdx: mod, reduceOnly: true });
						return { complete: { order: `MarketSell:${amount}`, stopLoss: `StopLoss at: ${stopLossPrice}`, partialClose: `Half close at: ${partialClose}` } };
					} catch (e) {
						return { error: e, from: "partialCloseOrder", complete: { order: `MarketSell:${amount}`, stopLoss: `StopLoss at: ${stopLossPrice}` } };
					}
				} catch (e) {
					return { error: e, from: "stopLossOrder", complete: { order: `MarketSell:${amount}` } };
				}
			} catch (e) {
				return { error: e, from: "order" };
			}
		}
	} else if (exchange.name === "Bitget") {
		if (type === "low") {
			try {
				const order = await exchange.createMarketBuyOrder(symbol, amount, {});
				try {
					const stopLossOrder = await exchange.createMarketBuyOrder(symbol, amount, { stopLossPrice, reduceOnly: true });
					try {
						const partialCloseOrder = await exchange.createMarketBuyOrder(symbol, amount / 2, { takeProfitPrice: partialClose, reduceOnly: true });
						return { complete: { order: `MarketBuy:${amount}`, stopLoss: `StopLoss at: ${stopLossPrice}`, partialClose: `Half close at: ${partialClose}` } };
					} catch (e) {
						return { error: e, from: "partialCloseOrder", complete: { order: `MarketBuy:${amount}`, stopLoss: `StopLoss at: ${stopLossPrice}` } };
					}
				} catch (e) {
					return { error: e, from: "stopLossOrder", complete: { order: `MarketBuy:${amount}` } };
				}
			} catch (e) {
				return { error: e, from: "order" };
			}
		} else {
			try {
				const order = await exchange.createMarketSellOrder(symbol, amount, {});
				try {
					const stopLossOrder = await exchange.createMarketSellOrder(symbol, amount, { stopLossPrice, reduceOnly: true });
					try {
						const partialCloseOrder = await exchange.createMarketSellOrder(symbol, amount / 2, { takeProfitPrice: partialClose, reduceOnly: true });
						return { complete: { order: `MarketSell:${amount}`, stopLoss: `StopLoss at: ${stopLossPrice}`, partialClose: `Half close at: ${partialClose}` } };
					} catch (e) {
						return { error: e, from: "partialCloseOrder", complete: { order: `MarketSell:${amount}`, stopLoss: `StopLoss at: ${stopLossPrice}` } };
					}
				} catch (e) {
					return { error: e, from: "stopLossOrder", complete: { order: `MarketSell:${amount}` } };
				}
			} catch (e) {
				return { error: e, from: "order" };
			}
		}
	}
};

export const searchingStopLossCandle = (df: any, arrLen: any, tradeType: any): any => {
	let candles = [] as any;
	for (let i = 0; i < 3; i++) {
		candles.push(df[arrLen - i][tradeType]);
	}
	let whichCandle: any | undefined;

	if (tradeType === "low") {
		whichCandle = candles.indexOf(Math.min(...candles));
	} else if (tradeType === "high") {
		whichCandle = candles.indexOf(Math.max(...candles));
	}
	if (whichCandle === 0) {
		const candle = df.filter((candle: any) => candle[tradeType] === candles[whichCandle]);

		return candle[candle.length - 1] as any;
	} else {
		return searchingStopLossCandle(df, arrLen - whichCandle, tradeType);
	}
};

export const tradeObjects = (type: "long" | "short", data: any, risk: number, userBalance: any) => {
	const maxLeverage = data?.maxLeverage ? data?.maxLeverage : 20;
	const fee = data?.fee;
	const lastPrice = data?.lastPrice;
	const stopLossPrice = type === "long" ? data?.stopLoss.low.price : data?.stopLoss.high.price;
	const stopLossPercentage = Math.abs((lastPrice - stopLossPrice) / lastPrice) * 100;
	const availableBalance = userBalance?.free.USDT / lastPrice;
	const leverage = risk / stopLossPercentage > 30 ? 30 : risk / stopLossPercentage;
	const amountLimit = availableBalance * maxLeverage - availableBalance * maxLeverage * fee;
	const orderAmount = leverage * availableBalance - leverage * availableBalance * fee;
	const amount = orderAmount > amountLimit ? amountLimit : orderAmount;
	const partialClose =
		type === "short"
			? lastPrice - ((lastPrice * stopLossPercentage) / 100) * 3 < lastPrice - lastPrice * 0.0065
				? lastPrice - ((lastPrice * stopLossPercentage) / 100) * 3
				: lastPrice - lastPrice * 0.0065
			: lastPrice + ((stopLossPercentage * lastPrice) / 100) * 3 > lastPrice + lastPrice * 0.0065
			? lastPrice + ((stopLossPercentage * lastPrice) / 100) * 3
			: lastPrice + lastPrice * 0.0065;

	return { amount: amount.toFixed(7), partialClose: partialClose, stopLossPrice, maxLeverage, lastPrice, stopLossPercentage, risk, leverage };
};
