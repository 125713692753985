import { User } from "firebase/auth";
import { setDoc, doc, getDoc, addDoc, collection, query, getDocs, where, deleteDoc } from "firebase/firestore/lite";
import { ccxt } from "..";
import { db } from "../firebase";
import { IExAccount } from "../atoms/userAccounts";

//유저 데이터 저장
export const userSignIn = async (user: User) => {
	const { email, displayName, photoURL, uid } = user;
	const docRef = doc(db, "users", uid);
	try {
		const exists = await (await getDoc(docRef)).exists();
		if (exists) {
			console.log("already signed in");
			return;
		}
		const saveUser = await setDoc(docRef, {
			email,
			displayName,
			photoURL,
		});
		console.log("Document written with ID: ", uid);
	} catch (e) {
		console.error("Error adding document: ", e);
	}
};
//유저 api키 저장
export const addAccount = async (userUid: string, { apiKey, secret, password, uid, exchange, name }: IExAccount) => {
	const queryName = query(collection(db, `users/${userUid}/apiKeys`), where("exchange", "==", exchange), where("name", "==", name));
	const queryApikey = query(collection(db, `users/${userUid}/apiKeys`), where("exchange", "==", exchange), where("apiKey", "==", apiKey));
	const querySnapshotForName = await getDocs(queryName);
	const querySnapshotForApikey = await getDocs(queryApikey);
	let nameExists = [] as string[];
	let apiExists = [] as string[];
	try {
		console.log("ccxt 확인");
		const targetExchange = new ccxt[exchange]({
			proxy: "https://rtw-proxy-server.herokuapp.com/",
			enableRateLimit: true,
			newUpdates: true,
			apiKey,
			secret,
			password: password ? password : null,
			uid: uid ? uid : null,
		});
		await targetExchange.fetchBalance();
	} catch (e) {
		console.log(e);
		console.log("stop???");
		return "Validation Failed Please Check Keys";
	}
	console.log("stop");
	querySnapshotForName.forEach((doc) => {
		nameExists.push(doc.id);
	});
	querySnapshotForApikey.forEach((doc) => {
		apiExists.push(doc.id);
	});

	if (nameExists[0]) return "Name is already used";
	if (apiExists[0]) return "ApiKey is already used";

	try {
		const saveMarket = await addDoc(collection(db, "users", userUid, "apiKeys"), {
			name,
			exchange,
			apiKey,
			secret,
			password: password ? password : null,
			uid: uid ? uid : null,
		});
		console.log("Document written with ID: ", saveMarket.id);
	} catch (e) {
		console.error("Error adding document: ", e);
	}
};
//유저 apiKey삭제
export const deleteAccount = async (userUid: string, { apiKey, secret, password, uid, exchange, name }: IExAccount) => {
	const queryName = query(collection(db, `users/${userUid}/apiKeys`), where("exchange", "==", exchange), where("name", "==", name));
	const querySnapshot = await getDocs(queryName);
	let targetAccount = [] as any;
	querySnapshot.forEach((doc) => {
		targetAccount?.push(doc.id as string);
	});
	console.log(targetAccount[0]);
	try {
		const deleteintgAccount = await deleteDoc(doc(db, `users/${userUid}/apiKeys/${targetAccount[0]}`));
		console.log(deleteintgAccount);
	} catch (e) {
		console.log(e);
	}
};

//유져 apiKey불러오기
export const getAccounts = async (user: User | null) => {
	if (!user) {
		return;
	}
	const { uid } = user;
	const q = query(collection(db, `users/${uid}/apiKeys`));
	const querySnapshot = await getDocs(q);
	let newAccounts = [] as any;
	querySnapshot.forEach((doc) => {
		newAccounts?.push(doc.data() as IExAccount);
	});
	return newAccounts;
};
//유저 정보 삭제
export const deleteUser = async () => {};
