import { useEffect } from "react";
import { IExAccount } from "../../atoms/userAccounts";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userAccountState } from "../../atoms/userAccounts";
import authState from "../../atoms/authAtom";
import { deleteAccount, getAccounts } from "../../database/users";
import { UserList } from "../../styleComponents/accountStyle";

const Deleting = (props: any) => {
	const account: IExAccount = props.account;
	const reFetchAccount = props.reFetchAccount;
	const [deleting, setDeleting] = props.deleteState;
	const user = useRecoilValue(authState);
	const setUserAccount = useSetRecoilState(userAccountState);

	useEffect(() => {
		(async () => {
			try {
				await deleteAccount(String(user?.uid), account);
				await getAccounts(user).then((accounts) => setUserAccount(accounts));
				setDeleting(false);
			} catch (e) {
				setDeleting(false);
			}
		})();
		return () => {
			reFetchAccount.refetch();
			console.log("yep");
		};
	}, []);

	return (
		<UserList key={account.name} initial={{ x: 50, opacity: 0, scale: 0 }} animate={{ x: 0, opacity: 1, scale: 1 }} exit={{ y: 100, opacity: 0, scale: 0 }}>
			<div>Deleting...</div>
		</UserList>
	);
};

export default Deleting;
