import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilBridgeAcrossReactRoots_UNSTABLE } from "recoil";
import styled from "styled-components";
import { authService } from "../firebase";
const ToggleButton = styled(motion.button)`
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	border: none;
	background: none;
	color: white;
	z-index: 5;
`;
const MenuSection = styled(motion.ul)`
	padding: 20px 10px;
	li {
		font-size: 35px;
		height: 40px;
	}
`;
const MenuWrapper = styled(motion.div)`
	position: fixed;
	top: 0px;
	right: 0;
	top: 65px;
	padding-left: 20px;
	padding-right: 20px;
	height: auto;
	width: auto;
	-webkit-backdrop-filter: blur(30px);
	backdrop-filter: blur(30px);
	border-radius: 0px 0px 0px 20px;
	border-bottom: 1px solid rgb(255, 255, 255, 0.2);
	border-left: 1px solid rgb(255, 255, 255, 0.2);
	background: rgba(255, 255, 255, 0.1);
	box-shadow: 9px 16px 16px hsl(0deg 0% 0% / 0.25);

	li {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);

		i {
			margin-right: 15px;
		}
	}
`;
const ToggleMenu = (props: any) => {
	const { componentRef } = props;
	return (
		<MenuWrapper key="toggleMenu" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
			<MenuSection
				ref={componentRef}
				initial={{ opacity: 0, height: 0, width: 0 }}
				animate={{ opacity: 1, width: 200, height: 150 }}
				exit={{ opacity: 0, width: 0, height: 0 }}>
				<motion.li initial={{ opacity: 0, height: 0, width: 0 }} animate={{ opacity: 1, width: 200, height: 40 }} exit={{ opacity: 0, width: 0, height: 0 }}>
					Need Help?
				</motion.li>
				<motion.li
					onClick={() => {
						authService.signOut();
					}}
					initial={{ opacity: 0, height: 0, width: 0 }}
					animate={{ opacity: 1, width: 200, height: 40 }}
					exit={{ opacity: 0, width: 0, height: 0 }}>
					<i className="fa-solid fa-right-from-bracket fa-2xs"></i>
					LogOut
				</motion.li>
			</MenuSection>
		</MenuWrapper>
	);
};

const HeaderToggleMenu = () => {
	const [toggle, setToggle] = useState(false);
	const componentRef = useRef<any>(null);
	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (componentRef.current && !componentRef.current.contains(event.target)) {
				setToggle(false);
			}
		};
		document.addEventListener("touchstart", handleClickOutside);
		return () => {
			document.removeEventListener("touchstart", handleClickOutside);
		};
	}, [componentRef]);

	return (
		<>
			{toggle ? (
				<ToggleButton key="togglebutton" onClick={() => setToggle(false)}>
					{/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
				<path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
			</svg> */}
					{/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg> */}
					<i className="fa-solid fa-xmark fa-2xl"></i>
				</ToggleButton>
			) : (
				<ToggleButton key="toggleButtonOpen" onClick={() => setToggle(true)}>
					<i className="fa-solid fa-bars fa-2xl"></i>
				</ToggleButton>
			)}
			{toggle ? <ToggleMenu componentRef={componentRef} /> : null}
		</>
	);
};

export default HeaderToggleMenu;
