import { signInWithPopup } from "firebase/auth";
import authState from "../atoms/authAtom";
import { authService, Providers } from "../firebase";
import { useSetRecoilState } from "recoil";
import { User } from "firebase/auth";
import { userSignIn } from "../database/users";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
	LoginWrapper,
	LoginButtons,
	LonginText,
	LonginTitle,
	LoginTitleVariants,
	LoginContainer,
} from "../styleComponents/loginStyle";

import UserAuth from "../Components/UserAuth";
import { RootBackgroundColor } from "../styleComponents/generalStyles";
import { LoadingMotion } from "../styleComponents/loadingStyle";
import WaveLoading from "../Components/WaveLoading";
import styled from "styled-components";
import { motion } from "framer-motion";
import InstallInstruction from "../Components/InstallInstruction";
import {
	BrowserView,
	IOSView,
	MobileView,
	isBrowser,
	isMobile,
	isChrome,
	isSafari,
	isMobileSafari,
	isSamsungBrowser,
} from "react-device-detect";

const Login = () => {
	const installed = window.matchMedia("(display-mode: standalone)").matches;
	const navigate = useNavigate();
	const AuthPrividersTitle = Object.keys(Providers);
	const setAuth = useSetRecoilState(authState);
	const [isLoading, setLoading] = useState(false);
	const { user } = UserAuth();

	const socialAuthHandler = async (provider: string) => {
		setLoading(true);
		try {
			const auth = await signInWithPopup(authService, Providers[provider]);
			if (auth?.user as User) {
				setAuth(auth.user);
				if (auth.operationType === "signIn") {
					await userSignIn(auth.user);
				}
				navigate("/");
			}
		} catch (error) {
			setLoading(false);
		}
	};
	useEffect(() => {}, []);

	return (
		<LoginWrapper>
			<LoginContainer
				initial={{
					backgroundImage: RootBackgroundColor.chart,
				}}
				exit={{ transition: { duration: 2 } }}>
				{isLoading ? <WaveLoading /> : null}
				{isLoading ? null : (
					<>
						<LonginTitle variants={LoginTitleVariants} initial="initial" animate="animate" exit="exit">
							<LonginText variants={LoginTitleVariants} style={{}}>
								READY
							</LonginText>
							<LonginText variants={LoginTitleVariants} style={{}}>
								TO
							</LonginText>
							<LonginText variants={LoginTitleVariants} style={{}}>
								WIN
							</LonginText>
						</LonginTitle>

						<LoginButtons
							initial={{ scale: 0, translateY: 100 }}
							animate={{ scale: 1, translateY: 0, transition: { type: "spring", bounce: 0.6 } }}
							exit={{ translateX: 500, opacity: 0, transition: { duration: 0.5 } }}>
							{AuthPrividersTitle.map((provider, index) => (
								<button key={index} data-provider={provider} onClick={() => socialAuthHandler(provider)}>
									Start With {provider.toUpperCase()}
								</button>
							))}
						</LoginButtons>

						{/* {installed ? (
							<LoginButtons
								initial={{ scale: 0, translateY: 100 }}
								animate={{ scale: 1, translateY: 0, transition: { type: "spring", bounce: 0.6 } }}
								exit={{ translateX: 500, opacity: 0, transition: { duration: 0.5 } }}>
								{AuthPrividersTitle.map((provider, index) => (
									<button key={index} data-provider={provider} onClick={() => socialAuthHandler(provider)}>
										Start With {provider.toUpperCase()}
									</button>
								))}
							</LoginButtons>
						) : !isSamsungBrowser && isMobile ? (
							<InstallInstruction />
						) : isMobile ? (
							<motion.div style={{ color: "white" }}>Please use chrome or chromium browser to install</motion.div>
						) : (
							<motion.div
								style={{ color: "", fontSize: "40px", background: "rgba(255,255,255,0.8)", borderRadius: "30px", padding: "20px", position: "absolute" }}>
								Please use mobile device
							</motion.div>
						)} */}
					</>
				)}
			</LoginContainer>
		</LoginWrapper>
	);
};

export default Login;
