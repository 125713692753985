import { motion } from "framer-motion";
import styled from "styled-components";

export const PositionLists = styled(motion.ul)`
	width: 100%;
	height: 100%;
	padding: 0 5%;
	overflow-y: scroll;
`;

export const PositionList = styled(motion.li)`
	font-size: 25px;
	display: flex;
	height: auto;
	align-content: space-between;
	justify-content: center;
	flex-direction: column;
	padding: 15px;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	background: rgba(255, 255, 255, 0.1);
	border-radius: 15px;
	margin-bottom: 15px;
	box-shadow: 9px 16px 16px hsl(0deg 0% 0% / 0.25);
`;
export const PositionListTop = styled(motion.div)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	line-height: 0.8;
	margin-bottom: 15px;
`;
export const PositionListBottom = styled(motion.div)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	line-height: 0.8;

	span {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`;

export const PositionListTopLeft = styled(motion.div)`
	display: flex;
	flex-direction: column;
`;

export const PositionSymbolName = styled(motion.span)``;
export const PositionPnL = styled(motion.span)``;

export const PositionLeverage = styled(motion.span)``;
export const PositionListTopRight = styled(motion.div)`
	display: flex;
	flex-direction: column;
	align-items: end;
`;

export const PositionPnLTitle = styled(motion.span)`
	color: orange;
	font-size: 16px;
`;
