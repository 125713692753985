import { motion } from "framer-motion";
import styled from "styled-components";
export const LoginWrapper = styled(motion.main)`
	height: 100svh;
	width: 100svw;
`;
export const LoginContainer = styled(motion.div)`
	padding: 20% 10%;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	overflow: hidden;
`;

export const LonginTitle = styled(motion.div)`
	width: 100svw;
	padding: 80px 50px;
	height: 400px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
`;
export const LoginButtons = styled(motion.div)`
	justify-content: center;
	align-items: center;
	flex-direction: column;

	button {
		width: 250px;
		height: 50px;
		outline: none;
		font-size: 20px;
		border-radius: 20px;
		border: none;
		color: white;
		background: tomato;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		box-shadow: 9px 16px 16px hsl(0deg 0% 0% / 0.25);
	}
`;
export const LonginText = styled(motion.span)`
	font-size: 100px;
	line-height: 0.8;
	font-weight: 500;
	color: rgba(255, 255, 255, 0.9);
`;
export const LoginTitleVariants = {
	initial: {
		opacity: 0,
		translateY: 100,
	},
	animate: {
		opacity: 1,
		translateY: 0,
		translateX: 0,
		transition: {
			type: "spring",
			bounce: 0.4,
			staggerChildren: 0.1,
		},
	},
};
