import { motion } from "framer-motion";
import styled from "styled-components";
export const Container = styled(motion.div)`
	width: 100%;
	height: 100%;
	padding: 0 10px;
`;
export const UserList = styled(motion.li)`
	width: 100%;

	display: flex;
	height: 80px;
	border-radius: 20px;
	a {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-align: center;
		border-bottom: 1px solid rgba(255, 255, 255, 0.4);
		margin-bottom: 0.9375rem;
		padding: 10px;
	}
`;
export const DeleteContainer = styled(motion.div)`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	border-bottom: 1px solid rgba(255, 255, 255, 0.4);
	margin-bottom: 0.9375rem;
	padding: 0px 0.3125rem;
	button {
		aspect-ratio: 1/1;
		font-size: 20px;
		background: none;
		border: none;
	}
`;

export const CheckButton = styled(motion.button)`
	color: limegreen;
	font-size: 20px;
	opacity: 0.8;
	background: none;
	border: none;
`;
export const CancelButton = styled(motion.button)`
	color: red;
	font-size: 20px;
	opacity: 0.8;
	background: none;
	border: none;
`;
export const Leftpart = styled(motion.div)`
	font-size: 30px;
	font-weight: 400;
`;
export const RightPart = styled(motion.div)`
	font-size: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	font-weight: 400;
	line-height: 1.2;
`;
export const RightPartDelete = styled(motion.div)`
	display: flex;
`;
