import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getAllCoins } from "../api/coinApi";
import { coinForTrade } from "../atoms/exchangeAtom";
import { CoinsCoin, CoinsCoinName, CoinsCoinsList, CoinsContainer, CoinsInfo, CoinsLiner, CoinsLogoImg, CoinsSearchForm } from "../styleComponents/coinsStyle";

import Header from "../Components/Header";
import { CenteredContainer, RootBackgroundColor, Wrapper } from "../styleComponents/generalStyles";
import WaveLoading from "../Components/WaveLoading";
import { AnimatePresence } from "framer-motion";
import { accountForTradeState, userAccountState } from "../atoms/userAccounts";

const Coins = () => {
	const { register, watch } = useForm();
	const { exchangeId, accountId } = useParams();
	const [coins, setCoins] = useState([] as any);
	const [search, setSearch] = useState([] as any);
	const [initializing, setInitialize] = useState(true);
	const setCoinForTrade = useSetRecoilState(coinForTrade);
	const accounts = useRecoilValue(userAccountState);
	const account = accounts.find((item) => item.exchange === exchangeId && item.name === accountId);
	const exchange = account?.ccxtExchange;

	useEffect(() => {
		(async () => {
			const getCoins = await getAllCoins(exchange);
			setCoins(getCoins as any);
			setInitialize(false);
		})();
	}, []);
	useEffect(() => {
		setSearch(coins);
	}, [coins]);

	useEffect(() => {
		const result = coins.filter((coin: any) => (coin.base.toUpperCase().includes(watch().keyword.toUpperCase()) ? coin : null));
		setSearch(result);
	}, [watch().keyword]);

	const imgError = async (e: any, coinId: string) => {
		const response = await fetch(`https://raw.githubusercontent.com/Pymmdrza/Cryptocurrency_Logos/8ac8cf844e92e3e5d94951e506197723f13f2b89/SVG/${coinId}.svg`);
		if (response.ok) {
			return (e.target.src = response.url);
		} else {
			return (e.target.src = "https://www.svgrepo.com/show/91549/question-mark-inside-a-circle.svg");
		}
	};

	return (
		<Wrapper wrapperColor={RootBackgroundColor.chart} exit={{ backgroundImg: RootBackgroundColor.chart }}>
			<Header pageTitle={exchangeId} />
			<CenteredContainer
				initial={{ opacity: 0, scale: 0 }}
				animate={{ opacity: 1, scale: 1 }}
				exit={{ opacity: 0, scale: 0, backgroundImage: RootBackgroundColor.chart }}
				style={{ display: "flex", flexDirection: "column" }}>
				<CoinsSearchForm whileTap={{ scale: 0.95 }} onSubmit={(e) => e.preventDefault()}>
					<input {...register("keyword")} placeholder="Type Coin Symbol" />
				</CoinsSearchForm>
				<AnimatePresence>
					{initializing ? (
						<WaveLoading key="loadingScreen" />
					) : (
						<CoinsLiner key="coinsLists">
							<CoinsContainer>
								<CoinsCoinsList>
									{search?.length === 0 && coins ? (
										<CoinsInfo>No Result</CoinsInfo>
									) : (
										search.map((coin: any) => (
											<CoinsCoin key={coin.baseId} whileTap={{ scale: 0.95 }}>
												<Link
													to={coin.baseId}
													onClick={async () => {
														if (coin?.info?.leverageFilter?.maxLeverage) {
															setCoinForTrade(coin);
														} else {
															await exchange.fetchLeverage(coin.symbol).then((response: any) => {
																setCoinForTrade({ ...coin, crossMarginLeverage: response.data.crossMarginLeverage });
															});
														}
													}}>
													<CoinsLogoImg
														onLoad={(e) => (e.currentTarget.hidden = false)}
														src={`https://www.bybit.com/bycsi-root/assets/image/coins/dark/${coin.baseId.toLowerCase()}.svg`}
														onError={(e) => {
															e.currentTarget.hidden = true;
															imgError(e, coin.baseId.toLowerCase());
														}}
													/>
												</Link>
												<CoinsCoinName>{coin.baseId}</CoinsCoinName>
											</CoinsCoin>
										))
									)}
								</CoinsCoinsList>
							</CoinsContainer>
						</CoinsLiner>
					)}
				</AnimatePresence>
			</CenteredContainer>
		</Wrapper>
	);
};
export default React.memo(Coins);
