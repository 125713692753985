import { IExAccount } from "../../atoms/userAccounts";
import { UserList, CancelButton, CheckButton, DeleteContainer, Leftpart, RightPartDelete } from "../../styleComponents/accountStyle";

const WantToDelete = (props: any) => {
	const account: IExAccount = props.account;
	const [wantDelete, setWantDelete] = props.state;
	const [deleting, setDeleting] = props.deleteState;

	console.log(wantDelete);
	return (
		<UserList key={`delete${account.name}`} initial={{ x: 300, opacity: 0, scale: 0 }} animate={{ x: 0, opacity: 1, scale: 1 }}>
			<DeleteContainer>
				<Leftpart>Delete {account.name}?</Leftpart>
				<RightPartDelete>
					<CheckButton
						whileTap={{ scale: 0.95 }}
						onClick={() => {
							setWantDelete(false);
							setDeleting(true);
						}}>
						<i className="fa-solid fa-check"></i>
					</CheckButton>
					<CancelButton whileTap={{ scale: 0.95 }} onClick={() => setWantDelete(false)}>
						<i className="fa-solid fa-xmark"></i>
					</CancelButton>
				</RightPartDelete>
			</DeleteContainer>
		</UserList>
	);
};
export default WantToDelete;
