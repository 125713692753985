import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import authState, { initializeAccount } from "../../atoms/authAtom";
import { addAccount } from "../../database/users";
import { AddAccountContainer, AddAccountErrors, AddAccountForm, AddAccountWrapper } from "../../styleComponents/addAccount";

const AddAccount = (props: any) => {
	const navigate = useNavigate();
	const user = useRecoilValue(authState);
	const location = useLocation().pathname;
	const exchagne = location.split("/")[location.split("/").length - 2];
	const { register, handleSubmit, formState, watch } = useForm();
	const { errors } = formState;
	const [validationCheck, setValidationCheck] = useState("");
	const [isLoading, setLoading] = useState(false);
	const reFetchAccount: any = useRecoilValue(initializeAccount);

	const onValid = async (data: any) => {
		setLoading(true);
		data.exchange = exchagne;
		const result = await addAccount(user?.uid as any, data);
		if (!result) {
			navigate("/exchanges");
		} else {
			setValidationCheck(result as any);
			setLoading(false);
		}
	};
	useEffect(() => {
		return () => {};
	}, []);

	return (
		<AddAccountWrapper
			style={{ position: "absolute", backdropFilter: "blur(15px)" }}
			initial={{ opacity: 0, scale: 0 }}
			animate={{ opacity: 1, scale: 1 }}
			exit={{ opacity: 0, scale: 0 }}>
			<AddAccountErrors>{validationCheck}</AddAccountErrors>
			<AddAccountContainer initial={{ opacity: 0, scale: 0, y: 100 }} animate={{ opacity: 1, scale: 1, y: 0 }} exit={{ opacity: 0, scale: 0, y: 100 }}>
				<AddAccountForm onSubmit={handleSubmit(onValid)}>
					<input {...register("name", { required: "Name is required" })} placeholder="Choose name for this account" />
					<AddAccountErrors>{errors?.name?.message as string}</AddAccountErrors>
					<input {...register("apiKey", { required: "API key is required" })} placeholder="API key" type="password" />
					<AddAccountErrors>{errors?.apiKey?.message as string}</AddAccountErrors>
					<input {...register("secret", { required: "Secret Key is required" })} placeholder="Secret key" type="password" />
					<AddAccountErrors>{errors?.secret?.message as string}</AddAccountErrors>
					{exchagne === "bitget" ? <input {...register("password", { required: "Passphase is reauired" })} placeholder="Passphase" type="password" /> : null}
					<AddAccountErrors>{errors?.password?.message as string}</AddAccountErrors>
					{!isLoading ? (
						<>
							<button>
								<span>Save</span>
							</button>
						</>
					) : (
						<button disabled>
							<span>Saving...</span>
						</button>
					)}
				</AddAccountForm>
			</AddAccountContainer>
		</AddAccountWrapper>
	);
};

export default AddAccount;
