import { AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useOutlet, useParams } from "react-router-dom";
import { useRecoilState, useRecoilStateLoadable, useRecoilValue, useSetRecoilState } from "recoil";
import { setupExchange } from "./api/coinApi";
import authState, { initializeAccount } from "./atoms/authAtom";
import { userAccountState } from "./atoms/userAccounts";
import UserAuth from "./Components/UserAuth";
import { getAccounts } from "./database/users";
import { authService } from "./firebase";
import { Wrapper } from "./styleComponents/generalStyles";
import { RootWrapper } from "./styleComponents/rootStyle";
import { RootBackgroundColor } from "./styleComponents/rootStyle";
import { Helmet } from "react-helmet";

function Root() {
	const location = useLocation();
	const outlet = useOutlet();
	const { user, isLoggedIn, isLoading } = UserAuth();
	const navigate = useNavigate();
	const [authUser, setAuthUser] = useRecoilState(authState);
	const [userAccounts, settUserAccounts] = useRecoilStateLoadable(userAccountState);
	const initializeAccounts = useQuery(
		"initializeAccounts",
		() =>
			getAccounts(authUser).then((response) => {
				settUserAccounts(
					response?.map((account: any) => {
						return { name: account.name, exchange: account.exchange, ccxtExchange: setupExchange(account) };
					})
				);
			}),
		{}
	);
	const setInitialize = useSetRecoilState(initializeAccount);
	useEffect(() => {
		if (!user && !isLoggedIn) {
			setAuthUser(null);
			authService.signOut();
			navigate("/login");
		} else if (!user && isLoggedIn) {
			setAuthUser(null);
			authService.signOut();
			navigate("/login");
		} else if (user && !isLoggedIn) {
			setAuthUser(null);
			authService.signOut();
			navigate("/login");
		}
	}, [isLoading, isLoggedIn, navigate]);

	useEffect(() => {
		setInitialize(initializeAccounts);
	}, []);

	return (
		<AnimatePresence>
			<Helmet>
				<title>Ready-To-Win</title>
			</Helmet>
			{initializeAccounts.isSuccess && userAccounts.contents ? (
				<RootWrapper key={location.pathname} transition={{ type: "sping" }}>
					{outlet}
				</RootWrapper>
			) : null}
		</AnimatePresence>
	);
}

export default Root;
