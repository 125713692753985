import { motion } from "framer-motion";
import styled from "styled-components";

export const LoadingWrapper = styled(motion.div)`
	width: 100%;
	height: auto;
	padding-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	color: white;
	z-index: 5;
	align-self: center;
	span {
		margin: 10px;
	}
`;
export const LoadingContainer = styled(motion.div)``;
export const LoadingText = styled(motion.span)`
	color: white;
	font-size: 100px;
`;
export const LoadingMotion = {
	start: {
		opacity: 0,
	},
	end: {
		opacity: 1,
		transition: {
			staggerChildren: 0.2,
		},
	},
};
