import { motion } from "framer-motion";
import styled from "styled-components";

export const ChartScreenWrapper = styled(motion.div)`
	padding-top: 65px;
	height: 100svh;
	width: 100svw;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	overflow: hidden;
`;

export const ChartScreenContainer = styled(motion.div)`
	width: auto;
	height: auto;
	display: flex;
	justify-content: center;
	border-radius: 30px;
	margin-bottom: 5%;
	color: white;
	font-size: 30px;
`;
export const ChartScreenBottomContainer = styled(motion.div)`
	width: auto;
	height: 100%;
	display: flex;
	justify-content: space-around;
	border-radius: 30px;
	color: white;
	font-size: 30px;
	align-items: center;
`;
export const ChartScreenChartContainer = styled(motion.div)`
	width: 100%;
	height: 60%;
	display: flex;
	margin-bottom: 10px;
	flex-direction: column;
	align-items: center;
`;
export const ChartScreenTopInfo = styled(motion.div)`
	box-sizing: border-box;
	padding: 0 3%;
	display: flex;
	justify-content: space-between;
	height: 10%;
	/* border-radius: 20px 20px 0 0; */
`;
export const ChartScreenTopInfoLeft = styled(motion.div)`
	color: white;
	display: flex;
	align-items: flex-start;
	font-size: 50px;
	flex-direction: column;
	box-sizing: border-box;
`;

export const ChartScreenTopInfoLeftTop = styled(motion.div)`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
	box-sizing: border-box;
`;
export const ChartScreenTopInfo_left_bottom = styled(motion.div)``;
export const ChartScreenRightPriceInfo = styled(motion.div)`
	color: white;
	display: flex;
	flex-direction: column;
	font-size: 18px;
	align-items: center;
	line-height: 1;
	span {
		margin-left: 10px;
		color: orange;
	}
`;
export const ChartScreenTimeDiv = styled(motion.ul)`
	color: white;
	font-size: 15px;
	padding-bottom: 10px;
	width: 200px;
	height: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 22px;
	box-sizing: border-box;

	li {
		display: flex;
		justify-content: center;
		aspect-ratio: 1/1;
		width: 30px;
		height: 20px;
		border-radius: 5px;
		margin-right: 5px;
		box-sizing: border-box;
		z-index: 3;
	}
	i {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		font-size: 20px;
	}
`;
export const ChartScreenRiskSelection = styled.select``;
export const ChartScreenTVbutton = styled(motion.button)`
	border: none;
	background: none;
	color: orange;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	height: 100%;
`;
export const TimeFrameSelection = styled(motion.li)``;
export const TimeFrameSelectorContainer = styled(motion.div)`
	display: flex;
	flex-direction: column;
`;
export const HighPriceInfo = styled(motion.span)`
	width: 100%;
	display: flex;
	justify-content: space-between;
	span {
		color: white;
	}
`;
export const LowPriceInfo = styled(motion.span)`
	width: 100%;
	display: flex;
	justify-content: space-between;
	span {
		color: white;
	}
`;
export const VolumeInfo = styled(motion.span)`
	width: 100%;
	display: flex;
	justify-content: space-between;
	span {
		color: white;
	}
`;
export const FundingInfo = styled(motion.span)`
	width: 100%;
	display: flex;
	justify-content: space-between;
	span {
		color: white;
	}
`;
export const FundingTime = styled(motion.span)`
	width: 100%;
	display: flex;
	justify-content: space-between;
	span {
		color: white;
	}
`;

export const TradeButtonContainer = styled(motion.div)`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const TradeButton = styled(motion.button)`
	width: 40%;
	height: 45px;
	border: none;
	margin: 0 5px;
	font-size: 20px;
	font-weight: 700;
	border-radius: 20px;
	color: white;
	box-shadow: 9px 16px 16px hsl(0deg 0% 0% / 0.5);
`;
