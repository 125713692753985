import { motion } from "framer-motion";

import { useEffect, useState } from "react";

import { TradeButton, TradeButtonContainer } from "../../styleComponents/chartScreenStyle";
import styled from "styled-components";
import { CancelButton, CheckButton } from "../../styleComponents/accountStyle";
import { trade, tradeObjects } from "../../api/coinApi";
import WaveLoading from "../WaveLoading";
import TextLoading from "../TextLoading";

const TradeInfo = styled(motion.div)`
	width: 90%;
	height: auto;
	border-radius: 15px;
	display: flex;
	align-items: center;
	padding: 8px 15px;
	justify-content: space-between;
	box-shadow: 9px 16px 16px hsl(0deg 0% 0% / 0.25);
`;
const TradeTitle = styled(motion.span)`
	font-size: 28px;
	font-family: 500;
`;
const TradeDesc = styled(motion.span)`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	color: white;
`;
const TradeComponent = (props: any) => {
	const { exchange, coin, tradeInfo, setTradeConfirm, tradeConfirm } = props;
	const [toggleTrade, setToggleTrade] = useState<"long" | "short" | null>(null);
	const [inform, setInform] = useState<any>(false);

	useEffect(() => {
		//매매 알림 수정 해야함 나중에
		// console.log(inform);
	}, [inform]);

	return (
		<TradeButtonContainer initial={{ opacity: 0, scale: 0, x: -200 }} animate={{ opacity: 1, scale: 1, x: 0 }} exit={{ opacity: 0, scale: 0, x: 200 }}>
			{!toggleTrade ? (
				<>
					<TradeButton
						initial={{ opacity: 0.2, scale: 1, x: -300 }}
						animate={{ opacity: 0.8, scale: 1, x: 0 }}
						exit={{ opacity: 0.2, scale: 1, x: -300 }}
						whileTap={{ scale: 0.95 }}
						onClick={() => {
							setToggleTrade("short");
						}}
						style={{ background: "red", borderTop: "1px solid rgba(255,255,255,0.5)" }}>
						Short
					</TradeButton>
					<TradeButton
						initial={{ opacity: 0.2, scale: 1, x: -300 }}
						animate={{ opacity: 0.8, scale: 1, x: 0 }}
						exit={{ opacity: 0.2, scale: 1, x: -300 }}
						whileTap={{ scale: 0.95 }}
						onClick={() => {
							setToggleTrade("long");
						}}
						style={{ background: "rgb(43,170,39)", borderTop: "1px solid rgba(255,255,255,0.5)" }}>
						Long
					</TradeButton>
				</>
			) : toggleTrade === "short" ? (
				<TradeInfo
					key="tradeInform"
					style={{ backgroundColor: "red" }}
					initial={{ opacity: 0, scale: 0, x: 300 }}
					animate={!inform ? { opacity: 0.8, scale: 1, x: 0 } : { opacity: 0.3, scale: 1, x: 0 }}
					exit={{ opacity: 0, scale: 0, x: -300 }}>
					<TradeDesc>
						<motion.span style={{ color: "orange", fontWeight: 500, fontSize: "16px", marginTop: -5 }}>Trade Info</motion.span>
						<motion.span style={{ display: "flex", flexDirection: "column", lineHeight: 0.7, fontSize: "16px", fontWeight: 500 }}>
							<span>
								<span style={{ color: "orange" }}>Order: </span>
								<span>{tradeInfo.short.amount}</span>
							</span>
							<span>
								<span style={{ color: "orange" }}>Leverage: </span>
								<span>{tradeInfo.short.leverage.toFixed(2)}</span>
							</span>
							<span>
								<span style={{ color: "orange" }}>SL%: </span>
								<span>{tradeInfo.short.stopLossPercentage.toFixed(2)}%</span>
							</span>
							<span>
								<span style={{ color: "orange" }}>Risk: </span>
								<span>{tradeInfo.short.risk}%</span>
							</span>
						</motion.span>
					</TradeDesc>
					<TradeTitle style={{ color: "white", fontWeight: 500 }}>ShortTrade</TradeTitle>
					{inform ? (
						<TextLoading fontSize="40px" margin="0 2px" />
					) : (
						<motion.div
							whileTap={{ scale: 0.95 }}
							style={{
								backgroundColor: "rgba(0,0,0,0.4)",
								display: "flex",
								alignItems: "center",
								padding: "5px",
								borderRadius: "20px",

								borderTop: "1px solid rgba(255,255,255,0.1)",
								boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)",
							}}>
							<CheckButton
								style={{ borderRight: "1px solid rgba(255,255,255,0.6)" }}
								disabled={inform}
								whileTap={{ scale: 0.95 }}
								onClick={async () => {
									setInform(true);
									await trade(exchange, coin, "high", tradeInfo.short).then(async (response: any) => {
										setInform(false);
										setTradeConfirm(response);
										setTimeout(() => setTradeConfirm(null), 3000);
									});
									setToggleTrade(null);
								}}>
								<i className="fa-solid fa-check"></i>
							</CheckButton>
							<CancelButton whileTap={{ scale: 0.95 }} disabled={inform} onClick={() => setToggleTrade(null)}>
								<i className="fa-solid fa-xmark"></i>
							</CancelButton>
						</motion.div>
					)}
				</TradeInfo>
			) : (
				<TradeInfo
					key="tradeInform"
					style={{ backgroundColor: "rgb(43,170,39)" }}
					initial={{ opacity: 0, scale: 0, x: 300 }}
					animate={!inform ? { opacity: 0.8, scale: 1, x: 0 } : { opacity: 0.5, scale: 1, x: 0 }}
					exit={{ opacity: 0, scale: 0, x: -300 }}>
					<TradeDesc>
						<motion.span style={{ color: "orange", fontWeight: 500, fontSize: "16px", marginTop: -5 }}>Trade Info</motion.span>
						<motion.span style={{ display: "flex", flexDirection: "column", lineHeight: 0.7, fontSize: "16px", fontWeight: 500 }}>
							<span>
								<span style={{ color: "orange" }}>Order: </span>
								<span>{tradeInfo.long.amount}</span>
							</span>
							<span>
								<span style={{ color: "orange" }}>Leverage: </span>
								<span>{tradeInfo.long.leverage.toFixed(2)}</span>
							</span>
							<span>
								<span style={{ color: "orange" }}>SL%: </span>
								<span>{tradeInfo.long.stopLossPercentage.toFixed(2)}%</span>
							</span>
							<span>
								<span style={{ color: "orange" }}>Risk: </span>
								<span>{tradeInfo.long.risk}%</span>
							</span>
						</motion.span>
					</TradeDesc>
					<TradeTitle style={{ color: "white", fontWeight: 700 }}>LongTrade</TradeTitle>
					{inform ? (
						<TextLoading fontSize="40px" margin="0 2px" />
					) : (
						<motion.div
							whileTap={{ scale: 0.95 }}
							style={{
								backgroundColor: "rgba(0,0,0,0.4)",
								display: "flex",
								alignItems: "center",
								padding: "5px 0",
								borderRadius: "20px",
								boxShadow: "9px 16px 16px hsl(0deg 0% 0% / 0.25)",
							}}>
							<CheckButton
								style={{ borderRight: "1px solid rgba(255,255,255,0.6)" }}
								disabled={inform}
								whileTap={{ scale: 0.95 }}
								onClick={async () => {
									setInform(true);
									await trade(exchange, coin, "low", tradeInfo.long).then((response) => {
										setInform(false);
										setTradeConfirm(response);
										setTimeout(() => setTradeConfirm(null), 3000);
									});
									setToggleTrade(null);
								}}>
								<i className="fa-solid fa-check"></i>
							</CheckButton>
							<CancelButton whileTap={{ scale: 0.95 }} disabled={inform} onClick={() => setToggleTrade(null)}>
								<i className="fa-solid fa-xmark"></i>
							</CancelButton>
						</motion.div>
					)}
				</TradeInfo>
			)}
		</TradeButtonContainer>
	);
};

export default TradeComponent;
