import { atom, selector } from "recoil";
import { UTCTimestamp } from "lightweight-charts";
import { searchingStopLossCandle, stopLossPaint } from "../api/coinApi";

export const allCoin = atom({
	key: "allCoins",
	default: [],
});
export const coinForTrade = atom({
	key: "coinForTrade",
});
interface IStoploss {
	long: number | null;
	short: number | null;
}
export const stopLossPriceState = atom<IStoploss>({
	key: "stopLossPrice",
	default: {
		long: null,
		short: null,
	},
});
interface IChartInfoState {
	candles: any;
	low: any;
	high: any;
	lastPrice: any;
}

export const percentageState = atom({
	key: "percentageInfo",
});
export const candleData = atom({
	key: "candleData",
});
export const tikerData = atom({
	key: "tikerData",
});
export const candleDataConverter = selector({
	key: "candleDataConverter",
	get: ({ get }) => {
		const candles = get(candleData) as any;
		const coin = get(coinForTrade) as any;
		const ticker = get(tikerData) as any;
		const { change, percentage, vwap, info } = ticker;
		const { taker, symbol } = coin;
		const maxLeverage = coin?.info?.leverageFilter?.maxLeverage ? coin?.info?.leverageFilter.maxLeverage : coin?.crossMarginLeverage;
		const converted = candles?.map((candle: any) => {
			return { time: (candle[0] / 1000) as UTCTimestamp, open: candle[1], high: candle[2], low: candle[3], close: candle[4], volume: candle[5] };
		});
		const { low, high } = stopLossPaint(converted);
		const lastPrice = converted[converted?.length - 1].close;

		return {
			candles: converted,
			low,
			high,
			lastPrice,
			stopLoss: {
				high: {
					price: high.value,
					percentage: (Math.abs(converted[converted.length - 1].close - high.value) / lastPrice) * 100,
				},
				low: {
					price: low.value,
					percentage: (Math.abs(converted[converted.length - 1].close - low.value) / lastPrice) * 100,
				},
			},
			maxLeverage,
			fee: taker * 2 * maxLeverage,
			change,
			percentage,
			vwap,
			info,
		};
	},
	dangerouslyAllowMutability: true,
});
